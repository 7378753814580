/* eslint-disable import/prefer-default-export */
export const loginAxiosConfig = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

export const getConfig = () => ({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('kdksoft-token')}`,
  }
});

export const getConnectorConfig = { crossdomain: true };