import React, { useState, useEffect } from 'react';
import { Link, useLocation, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import $ from 'jquery';
import moment from 'moment';
import queryString from 'query-string';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faSync, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import ShowAlert from '../../components/ShowAlert';
import GstPortalLoginConnector from './GstPortalLoginConnector';
import {
  syncStatus,
} from '../../api/2adataAPI';
import './Header.css';
import NumericInput from '../../components/NumericInput';
import Exgstlogo from '../../images/logo/exgstlogo.svg';
import ExgstDiwalilogo from '../../images/logo/exgstlogo_diwali.svg';
import Abv1logo from '../../images/logo/abv1logo.svg';
import Abv2logo from '../../images/logo/abv2logo.svg';
import IndiaCAlogo from '../../images/logo/icalogo.svg';
import logouticon from '../../images/loginicon/logouticon.png';
import callblueIcon from '../../images/call-blue.png';
import callIcon from '../../images/call.png';
import searchIcon from '../../images/search_gstin.png';
import closeIcon from '../../images/loginicon/verifyInvalidpopiocn.png';
import {
  BASE_PATH, LOGO, initialHeaderData, SYNC_THRESHOLD_TS, SYNC_REATTEMPTS_DAYS,
} from '../../app/constants';
import SyncData from '../2AData/SyncData';
import {
  selectHeaderData,
  setHeader,
  //setSelectedYearsRedu, 
  //setCheckMonthsRedu 
} from './headerSlice';
import { getCurrentFinancialYear, getFinancialYears, getFinancialMonths, getFinancialMonthsReco, getYearFromFYandMonth,
   getFyFromYearAndMonth, validateGSTNumber } from '../../utils/UtilityFunctions';
import { encodeParamsHeaderMulti, decodeParamsHeaderMulti, } from '../../utils/UtilityFunctions';
import { getGSTInfo, getReturnFillingStatus } from '../../api/returnAPI';
import GstInfoAnimation from '../returnDashboard/GstInfoAnimation';
import { addCallMe, getGstInfo, getRecoSyncStatus } from '../../api/profileAPI';
import { updateCompanyDetails } from '../../api/companyAPI';
import SubscribePopup from '../Plans/SubscribePopup';
import Notifications from '../Notifications';
import { LOGIN_PATHS } from '../../App';
import GstinInfoModel from './GstinInfoModel';
import './headerReco.scss'
import DraggableContent from '../../components/grid/DraggableContent';

// Modify Logo, as per Cobrand identified. If not, default to Express GST Logo
let logo_touse = Exgstlogo;
const logo_uc = LOGO.toUpperCase();
if (logo_uc === "ABV1") {
  logo_touse = Abv1logo;
} else if (logo_uc === "ABV2") {
  logo_touse = Abv2logo;
} else if (logo_uc === "EXGSTDIWALI") {
  logo_touse = ExgstDiwalilogo;
} else if (logo_uc === "ICAIV1") {
  logo_touse = IndiaCAlogo;
}


const Header = ({ history }) => {
  const headerData = useSelector(selectHeaderData);

  const dispatch = useDispatch();
  const recoInProgressMessage = "Reconciliation is in-progress. Kindly refresh to check updated data once it is completed.";
  const recocompletedMessage = "Reconciliation is complete. Kindly refresh to check updated data.";
  const location = useLocation();
  const params = queryString.parse(location.search);
  const windowWidth = window.innerWidth;
  const { year, return_financial_year } = params;

  const gstr1_filing_path = location.pathname.includes('gstr1/file-on-portal')
    || location.pathname.includes('iff/file-on-portal');

  const company_id = params.company_id ?? null;
  const start_gstr1_upload = parseInt(params.start_gstr1_upload ?? 0);

  const tourPaths = [
    `${BASE_PATH}`,
    `${BASE_PATH}company/dashboard`,
  ];

  const supplierPaths = [
    //`${BASE_PATH}supplier-reco`,
    //`${BASE_PATH}supplier-reco/2b`,
    //`${BASE_PATH}invoice-reco/2b`,
    `${BASE_PATH}company/dashboard`,
    //`${BASE_PATH}supplier-email`,
    // `${BASE_PATH}month-reco`,
    `${BASE_PATH}gstr1-reco/summary`,
    `${BASE_PATH}gstr1-reco/detail/summary`,
    `${BASE_PATH}gstr1-reco/detail/docs`,
    `${BASE_PATH}gstr1-reco/party/summary`,
    //`${BASE_PATH}rate-wise-reco`,

  ];

  const recoDashboardpath = [
    `${BASE_PATH}reco-dashboard`,
    `${BASE_PATH}multi-linking`,
    `${BASE_PATH}invoice-reco`,
    `${BASE_PATH}rate-reco/2b`,
    `${BASE_PATH}rate-reco`,
    `${BASE_PATH}invoice-rate-reco/2b`,
    `${BASE_PATH}invoice-rate-reco`,
    `${BASE_PATH}supplier-reco/2b`,
    `${BASE_PATH}supplier-reco`,
    `${BASE_PATH}invoice-reco/2b`,
    `${BASE_PATH}supplier-email`,
    `${BASE_PATH}month-reco`
  ]

  const gstr1Paths = [
    `${BASE_PATH}gstr1/books/summary`,
    `${BASE_PATH}gstr1/books/detail`,
    `${BASE_PATH}gstr1/books/save-data`,
    `${BASE_PATH}gstr1/books/submit-data`,
    `${BASE_PATH}gstr1/portal/summary`,
    `${BASE_PATH}gstr1/portal/detail`,
    `${BASE_PATH}gstr1/portal/save-data`,
    `${BASE_PATH}gstr1/file-on-portal`
  ];

  const gstr1aPaths = [
    `${BASE_PATH}gstr1a/books/summary`,
    `${BASE_PATH}gstr1a/books/detail`,
    `${BASE_PATH}gstr1a/books/save-data`,
    `${BASE_PATH}gstr1a/books/submit-data`,
    `${BASE_PATH}gstr1a/portal/summary`,
    `${BASE_PATH}gstr1a/portal/detail`,
    `${BASE_PATH}gstr1a/portal/save-data`,
    `${BASE_PATH}gstr1a/file-on-portal`
  ];


  const gstr3BPaths = [
    `${BASE_PATH}gstr3b/itc-utilization`,
    `${BASE_PATH}gstr3b/inward-outward-supply`,
    `${BASE_PATH}gstr3b/review`,
    `${BASE_PATH}gstr3b/eligible-itc`,
    `${BASE_PATH}gstr3b/offset-liability`,
    `${BASE_PATH}gstr3b/file-3b`,
    `${BASE_PATH}gstr3b/consolidated`,
    `${BASE_PATH}claim-itc`,
    `${BASE_PATH}party-wise-claim-itc`,
    `${BASE_PATH}gstr3b/itc-type`,
  ];

  const gstr9Paths = [
    `${BASE_PATH}gstr9/sales`,
    `${BASE_PATH}gstr9/itc`,
    `${BASE_PATH}gstr9/tax-paid`,
    `${BASE_PATH}gstr9/fy-details`,
    `${BASE_PATH}gstr9/others`,
    `${BASE_PATH}gstr9/review`,
    `${BASE_PATH}gstr9/save`,
    `${BASE_PATH}gstr9/snapshots`,
  ];
  const gstr9CPaths = [
    `${BASE_PATH}gstr9c/reco-turnover`,
    `${BASE_PATH}gstr9c/reco-tax-paid`,
    `${BASE_PATH}gstr9c/reco-itc`,
    `${BASE_PATH}gstr9c/additional-liability`,
    `${BASE_PATH}gstr9c/review`,
    `${BASE_PATH}gstr9c/save`,
  ];

  const iffPaths = [
    `${BASE_PATH}iff/books/summary`,
    `${BASE_PATH}iff/books/detail`,
    `${BASE_PATH}iff/books/save-data`,
    `${BASE_PATH}iff/books/submit-data`,
    `${BASE_PATH}iff/portal/summary`,
    `${BASE_PATH}iff/portal/detail`,
    `${BASE_PATH}iff/portal/save-data`,
    `${BASE_PATH}iff/file-on-portal`,
  ];

  const simpleHeaderPaths = [
    `${BASE_PATH}`,
    `${BASE_PATH}client/all`,
    `${BASE_PATH}gstin-login`,
    `${BASE_PATH}update-company`,
    `${BASE_PATH}user-management`,
    `${BASE_PATH}user-profile`,
    `${BASE_PATH}profile`,
    `${BASE_PATH}plans`,
    `${BASE_PATH}billing`,
    `${BASE_PATH}schedule-2a-2b-reco-email`,
    `${BASE_PATH}email-review`,
    `${BASE_PATH}pub-auth/sync-2a-2b-mail`,
    `${BASE_PATH}return-register/client-wise`,
    `${BASE_PATH}return-register/month-wise`,
    `${BASE_PATH}subscription`,
    `${BASE_PATH}orders-and-notices`,
    `${BASE_PATH}client-master-bulkimport`,
  ];

  const nonCompanyHeaderPaths = [
    `${BASE_PATH}`,
    `${BASE_PATH}client/all`,
    `${BASE_PATH}user-management`,
    `${BASE_PATH}profile`,
    `${BASE_PATH}plans`,
    `${BASE_PATH}billing`,
    `${BASE_PATH}schedule-2a-2b-reco-email`,
    `${BASE_PATH}email-review`,
    `${BASE_PATH}return-register/client-wise`,
    `${BASE_PATH}return-register/month-wise`,
    `${BASE_PATH}subscription`,
    `${BASE_PATH}return-register/client-wise`,
    `${BASE_PATH}return-register/month-wise`,
    `${BASE_PATH}orders-and-notices`,
    `${BASE_PATH}client-master-bulkimport`,
    `${BASE_PATH}gstin-login`,
    `${BASE_PATH}e-invoice`,
    `${BASE_PATH}einvoice-login`,
    `${BASE_PATH}edit-user-invoice`,
  ];

  const nonCompanySyncPaths = [
    `${BASE_PATH}gstin-login`,
    `${BASE_PATH}update-company`,
    `${BASE_PATH}pub-auth/sync-2a-2b-mail`,
  ];

  const planNotShowPath = [
    `${BASE_PATH}plans`,
    `${BASE_PATH}profile`,
    `${BASE_PATH}subscription`,
    `${BASE_PATH}user-management`,
    `${BASE_PATH}user-profile`,
    `${BASE_PATH}login`,
    `${BASE_PATH}signup`,
    `${BASE_PATH}forgetpass`,
  ];

  const recoStatusBarShowPath = [
    `${BASE_PATH}reco-dashboard`,
    `${BASE_PATH}rate-reco/2b`,
    `${BASE_PATH}rate-reco`,
    `${BASE_PATH}invoice-rate-reco/2b`,
    `${BASE_PATH}invoice-rate-reco`,
    `${BASE_PATH}invoice-reco/2b`,
    `${BASE_PATH}invoice-reco`,
    `${BASE_PATH}supplier-reco/2b`,
    `${BASE_PATH}supplier-reco`,
    `${BASE_PATH}multi-linking`,
    `${BASE_PATH}supplier-email`,
    `${BASE_PATH}claim-itc`,
    `${BASE_PATH}party-wise-claim-itc`,
  ]
  const monthDropDown = gstr1Paths.includes(location.pathname) || gstr1aPaths.includes(location.pathname) || iffPaths.includes(location.pathname) || gstr3BPaths.includes(location.pathname);

  //eslint-disable-next-line
  const [initialRender, setInitialRender] = useState(true);
  const [filterMonth, setFilterMonth] = useState(false);
  const [filterYear, setFilterYear] = useState(false);
  const [syncLoading] = useState(false);
  const [showSyncVerify, setShowSyncVerify] = useState(false);
  const [monthList, setMonthList] = useState([]);
  const [fyList, setFyList] = useState([]);
  const [showGstInfoPopup, setShowGstInfoPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [gstInfo, setGstInfo] = useState(false);
  const [syncStatusFailureMsg, setSyncStatusFailureMsg] = useState('');
  const [syncStatusRemindLater, setSyncStatusRemindLater] = useState(false);
  const [monthDropDownPopup, setMonthDropDownPopup] = useState(false);
  const [returnFillingStatus, setReturnFillingStatus] = useState([]);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [syncStatusData, setSyncStatusData] = useState({});
  const [callMe, setCallMe] = useState(headerData.mobile_number);
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);
  const [showGstSearch, setShowGstSerach] = useState(false)
  const [showGstInModel, setShowGstInModel] = useState(false)
  const [gstInData, setGstInData] = useState({})
  const [gstInNumber, setGstInNumber] = useState('')
  const [sendGstNumber, setSendGstNumber] = useState('')
  const [monthBasedYear, setMonthBasedYear] = useState([])
  const [recoSyncStatus, setRecoSyncStatus] = useState(null);
  const [selectedYears, setSelectedYears] = useState([]);
  const [savePathName, setSavePathName] = useState(''); // this state use only reco status path api 
  const CURRENT_YEAR = parseInt(moment().format("YYYY"));
  const CURRENT_MONTH = parseInt(moment().format("M"));


  const addCallMeData = async () => {

    if (callMe >= 5999999999 && callMe <= 9999999999) {
      setLoading(true);
      const data = {
        mobile_number: callMe,
      };
      try {
        const result = await addCallMe(data);
        setSuccess(result.message);
      } catch (err) {
        setError(err.message);
        console.error('Error in Call Me API: ', err);
      }
      setLoading(false);
    }
    else {
      setError("Invalid Mobile Number");
    }
  }

  const updateCompanyData = async (data) => {
    setLoading(true);
    data['company_id'] = company_id;
    try {
      const result = await updateCompanyDetails(data);
      setSuccess(result.message);
    } catch (err) {
      setError(err.message);
      console.error('Error in updating: ', err);
    }
    setLoading(false);
  }

  const toggleCompanyTurnoverData = () => {
    let newStatus = !headerData.turnover_above_5cr;
    dispatch(setHeader({ turnover_above_5cr: newStatus }));
    updateCompanyData({ turnover_above_5cr: newStatus });
  }

  const checkRemindLater = (remind_later_ts) => {
    if (remind_later_ts === null) {
      return false;
    }
    const currentTime = moment().unix();
    if (currentTime > remind_later_ts) {
      return false;
    }
    return true;
  };


  const getReturnFillingStatusData = async (gst_number, companyid) => {
    setLoading(true);
    try {

      let return_filling_status_data = localStorage.getItem('return-filling-status-data-v4');
      return_filling_status_data = return_filling_status_data ? JSON.parse(return_filling_status_data) : [];

      let remove_index = -1;
      let current_gst_return_data = return_filling_status_data.filter((return_data, index) => {
        if (return_data.gst_number === gst_number) { remove_index = index };
        return return_data.gst_number === gst_number;
      });

      if (current_gst_return_data[0] && current_gst_return_data[0].timestamp > moment().unix()) {
        dispatch(setHeader({ gstr_filed_status: current_gst_return_data[0].data }));
        setReturnFillingStatus(current_gst_return_data[0].data);
        redirectReturnDashboard(current_gst_return_data[0].data);
      }
      else {
        const result = await getReturnFillingStatus(companyid);
        if (result.data) {
          //save in local storage
          if (remove_index > -1) {
            return_filling_status_data[remove_index] = { gst_number: gst_number, data: result.data, timestamp: moment().add(1, 'days').unix() };
          }
          else {
            return_filling_status_data.push({ gst_number: gst_number, data: result.data, timestamp: moment().add(1, 'days').unix() });
          }
          localStorage.setItem('return-filling-status-data-v4', JSON.stringify(return_filling_status_data));
          dispatch(setHeader({ gstr_filed_status: result.data }));
          setReturnFillingStatus(result.data);
          redirectReturnDashboard(result.data);
        }
      }

    } catch (error) {
      console.error("Error in fetching Return filing status data: ", error);
    } finally {
      setLoading(false);
    }
  }

  const checkTourGuide = () => {
    const intro_guide_ts = localStorage.getItem(`intro_guide_ts_${headerData.mobile_number}_return_dashboard`);
    if (!intro_guide_ts) {
      setTimeout(function () { $("body").css('overflow', 'hidden'); $("#restart_guide").trigger("click"); }, 100);
    }
  }

  const getSyncStatus = (company_id) => {
    async function fetchEverything() {
      async function fetchSyncStatus() {
        const response = await syncStatus(company_id);
        setSyncStatusData(response.data);
        const gstNumber = response.header && response.header.gst_number ? response.header.gst_number : '';
        const remind_later_ts = localStorage.getItem(`remind_later_ts${gstNumber}`);
        const remindLaterStatus = checkRemindLater(remind_later_ts);
        setSyncStatusRemindLater(true);
        if (response && response.data && response.data.updated_at_unixts === null && remindLaterStatus === false) {
          setSyncStatusFailureMsg('It seems that your portal data is not up-to-date. You are advised to re-enter captcha. Please do not login at GSTN portal while the sync is running.!');
          setShowSyncVerify(true);
        } else if (response && response.data && response.data.status === 'FAILURE' && remindLaterStatus === false) {
          setSyncStatusFailureMsg('It seems that your portal data is not up-to-date. You are advised to re-enter captcha. Please do not login at GSTN portal while the sync is running.!');
          setShowSyncVerify(true);
        } else if (response && response.data && response.data.status === 'SUCCESS') {
          const minSyncTsRequired = response.data.updated_at_unixts + (SYNC_REATTEMPTS_DAYS * 24 * 60 * 60);
          const lastSyncedAtUnix = response.data.updated_at_unixts;

          if (SYNC_THRESHOLD_TS !== null && lastSyncedAtUnix < moment(SYNC_THRESHOLD_TS, 'DD/MM/YYYY hh:mm:ss').unix() && remindLaterStatus === false) {
            setSyncStatusFailureMsg('It seems that your portal data is not up-to-date. You are advised to re-enter captcha. Please do not login at GSTN portal while the sync is running.!');
            setShowSyncVerify(true);
          }

          if (minSyncTsRequired < moment().unix() && remindLaterStatus === false) {
            setSyncStatusFailureMsg('Seems your portal data is not updated. You are advised to re-enter captcha. Please do not login in GSTN portal during this process.!');
            setShowSyncVerify(true);
          }
        }
      }
      try {
        await Promise.all([
          fetchSyncStatus(),
        ]);
        // setError(null);
      } catch (err) {
        // setError(err.message);
      } finally {
        //setSyncLoading(false);
      }
    }
    //setSyncLoading(true);
    fetchEverything();
  };

  const getGSTInfoData = async (gst_number) => {
    setLoading(true);
    try {
      const result = await getGSTInfo(`company_gst_number=${gst_number}`);
      if (result.data) {
        setGstInfo(result.data);
        setShowGstInfoPopup(true);
      }
    } catch (error) {
      console.error('Error in fetching GST Info: ', error);
    } finally {
      setLoading(false);
    }
  };

  const searchGstIn = async (gstNumber) => {
    if (validateGSTNumber(gstNumber)) {
      setShowGstSerach(false)
      setSendGstNumber(gstInNumber)
      setGstInNumber("")
      setError(null);
      setLoading(true);
      const data = {
        gst_number: gstNumber,
        company_id: company_id,
      };
      try {
        const result = await getGstInfo(data);
        if (result?.data) {
          setGstInData(result?.data);
          setShowGstInModel(true)
        }
      } catch (err) {
        setError(err.message);

      }
      setLoading(false);
    }
    else if (gstNumber.length > 0) {
      setError("Invalid GST  Number");
    }
  }

  const changeFilter = (value, type) => {
    let filter_year = filterYear ? filterYear.value : 0;
    let filter_month = filterMonth ? filterMonth.value : 0;
    if (type === 'month') {
      setFilterMonth(value);
      filter_month = value.value;
    } else {
      setFilterYear(value);
      filter_year = value.value;
    }

    historyUpdate(filter_year, filter_month);
  };

  const changeYearFilter = (value) => {
    let filter_year = filterYear ? filterYear.value : 0;
    const filter_month = filterMonth ? filterMonth.value : 0;
    setFilterYear(value);
    filter_year = value.value;
    if (!monthDropDown) {
      historyUpdate(filter_year, filter_month);
    } else {
      let month_list = getFinancialMonths(value.value ?? getCurrentFinancialYear(), headerData.registration_date);
      month_list = month_list.filter((month) => !month.disable);
      setMonthList(month_list);
      $('#monthDropDownPopup').focus();
    }
  };

  const historyUpdate = (fy, month = false) => {
    let y = fy;
    if (month && monthDropDown) {
      y = getYearFromFYandMonth(fy, month);
    }
    let filterstr = `?company_id=${company_id}&year=${y}&return_financial_year=${parseInt(fy)}`;
    if (month && monthDropDown) {
      filterstr += `&month=${month}`;
    }

    if (params.gstr) {
      filterstr += `&gstr=${params.gstr}`;
    }

    if (params.filter_activity_status) {
      filterstr += `&filter_activity_status=${params.filter_activity_status}`;
    }

    let filter_period_values = [];
    if (params.filter_period_values) {

      const currentYear = parseInt(moment().format('YYYY'));
      const currentMonth = parseInt(moment().format('M'));

      filter_period_values = params.filter_period_values.split(',');
      if (parseInt(fy) === 2017) {
        filter_period_values = filter_period_values.filter((period) => period !== 'Q1' && period !== 'APR' && period !== 'MAY' && period !== 'JUN');
      }

      if (parseInt(currentYear) === parseInt(fy) && params.filter_period === 'month') {
        filter_period_values = filter_period_values.filter((period) => {
          if (period !== 'JAN' && period !== 'FEB' && period !== 'MAR') {
            return moment(`01 ${period} ${currentYear}`, 'DD MMM YYYY').format("M") <= currentMonth;
          }
          else {
            return moment(`01 ${period} ${currentYear}`, 'DD MMM YYYY').format("M") <= currentMonth && currentMonth < 4;
          }
        });
      }

      if (parseInt(currentYear) === parseInt(fy) && params.filter_period === 'quarter') {
        filter_period_values = filter_period_values.filter((period) => {
          if (period === 'Q1' && currentMonth >= 4) {
            return true
          }
          else if (period === 'Q2' && currentMonth >= 7) {
            return true
          }
          else if (period === 'Q3' && currentMonth >= 10) {
            return true
          }
          else if (period === 'Q4' && currentMonth >= 1 && currentMonth <= 3) {
            return true
          }
          else {
            return false;
          }
        });
      }

    }

    if (filter_period_values.length > 0) {
      filterstr += `&filter_period=${params.filter_period}&filter_period_values=${filter_period_values.join(',')}`;
    } else {
      filterstr += '&filter_period=year';
    }

    if (params.sort_on) {
      filterstr += `&sort_on=${params.sort_on}&order_by=${params.order_by}`;
    }
    if (params.filters) {
      filterstr += `&filters=${params.filters}`;
    }
    if (params.page) {
      filterstr += `&page=${params.page}`;
    }
    if (params.limit) {
      filterstr += `&limit=${params.limit}`;
    }
    history.push(filterstr);
  };
 
  // last sync status API  IN_PROGRESS 
  const getRecoSyncStatusOnServer = async (lastSyncStatus = null) => { 
    if(!params?.company_id) return;
    const urlparams = `?company_id=${params?.company_id}${lastSyncStatus !== null ? `&reco_status=${lastSyncStatus}` : ""}`;

    try {
      const result = await getRecoSyncStatus(urlparams);
      const status = result?.data?.reco_status === 1 ? "IN_PROGRESS" : result?.data?.reco_status === 0 ? "COMPLETE" : null;
      setRecoSyncStatus(status)
      if (status === "IN_PROGRESS") {
        setTimeout(() => {
          getRecoSyncStatusOnServer(result?.data?.reco_status);
        }, 5000);
        return;
      }else if(status === "COMPLETE"){
        setTimeout(() => {
          history.go()
        }, 5000);
      } 
    } catch (err) {
      if (err.message) {
        setError(err.message)
      } else {
        setError(err);
      }
    }
  }

  useEffect(() => {
    const new_params = queryString.parse(location.search);
    let param_fy = parseInt(new_params.return_financial_year ?? 0);
    const param_month = parseInt(new_params.month ?? 0);
    const param_year = parseInt(new_params.year ?? 0);

    // Calculate FY, if not available in params
    if (param_fy <= 0) {
      param_fy = param_year;

      if (param_month > 0) {
        param_fy = getFyFromYearAndMonth(param_year, param_month);
      }
    }

    // set year and month dropdown
    let fy_list = getFinancialYears(headerData.registration_date ?? '');

    //ITC Claim years >= 2020
    if ([`${BASE_PATH}claim-itc`, `${BASE_PATH}party-wise-claim-itc`].includes(location.pathname)) {
      fy_list = fy_list.filter(v => parseInt(v.value) >= 2020);
    }

    let month_list = getFinancialMonths(param_fy ?? getCurrentFinancialYear(), headerData.registration_date);
    month_list = month_list.filter((month) => !month.disable);
    // Initialize checked property for each month
    month_list = month_list.map(month => ({ ...month, checked: false }));
    // Initialize checked property for each month

    setMonthList(month_list);
    setFyList(fy_list);

    // set current selected FY
    if (param_fy > 0) {
      const selected_fy = fy_list.filter((fy) => parseInt(fy.value) === parseInt(param_fy));
      setFilterYear(selected_fy[0]);
    } else {
      setFilterYear(getCurrentFinancialYear());
    }

    // set current selected month
    if (param_month > 0) {
      const selected_month = month_list.filter((month) => parseInt(month.value) === parseInt(param_month));
      setFilterMonth(selected_month[0]);
    } else {
      setFilterMonth(month_list[0]);
    }

  }, [location, headerData]);


  useEffect(() => {
    if (company_id && company_id !== undefined && !nonCompanySyncPaths.includes(location.pathname)) {
      getSyncStatus(company_id);
    } else {
      setShowSyncVerify(false);
      dispatch(setHeader({ status: '', updated_at: '', updated_at_unixts: '' }));
    }
    //eslint-disable-next-line
  }, [company_id]);


  const logOut = (e) => {
    e.preventDefault();
    localStorage.setItem('kdksoft-token', null);
    localStorage.removeItem('address_available');
    dispatch(setHeader(initialHeaderData));
    history.push(`${BASE_PATH}login`);
  };

  const redirectReturnDashboard = (returnFillingData) => {
    let params_data = queryString.parse(location.search);
    let param_year = params_data.year;

    if (gstr1Paths.includes(location.pathname) && param_year >= 2021 && returnFillingData[param_year] && returnFillingData[param_year][params_data.month] && returnFillingData[param_year][params_data.month].iff_applicable) {
      history.push(`${location.pathname.replace('gstr1', 'iff')}${location.search}`);
    }

    if (iffPaths.includes(location.pathname) && returnFillingData[param_year] && returnFillingData[param_year][params_data.month] && !returnFillingData[param_year][params_data.month].iff_applicable) {
      history.push(`${location.pathname.replace('iff', 'gstr1')}${location.search}`);
    }

    if (gstr1Paths.includes(location.pathname) && param_year >= 2021 && returnFillingData[param_year] && returnFillingData[param_year][params_data.month] && !returnFillingData[param_year][params_data.month].iff_applicable && !returnFillingData[param_year][params_data.month].gstr1_applicable) {
      history.push(`${BASE_PATH}company/dashboard?company_id=${company_id}&year=${param_year}`);
    }

    if (!checkMonthFilterActive(returnFillingData, (params_data.month && params_data.month < 4) ? param_year - 1 : param_year, params_data.month) && gstr1Paths.includes(location.pathname) && iffPaths.includes(location.pathname)) {
      history.push(`${BASE_PATH}company/dashboard?company_id=${company_id}&year=${param_year}`);
      setError(`For ${headerData.gst_number}, GSTR 1 is not Applicable for the period ${moment(params_data.year + '-' + params_data.month + '-01').format("MMMM")} -${params_data.year}`);
    }

    if (!checkMonthFilterActive(returnFillingData, (params_data.month && params_data.month < 4) ? param_year - 1 : param_year, params_data.month) && gstr1aPaths.includes(location.pathname)) {
      history.push(`${BASE_PATH}company/dashboard?company_id=${company_id}&year=${param_year}`);
      setError(`For ${headerData.gst_number}, GSTR 1A is not Applicable for the period ${moment(params_data.year + '-' + params_data.month + '-01').format("MMM")} -${params_data.year}`);
    }

    if (!checkMonthFilterActive(returnFillingData, (params_data.month && params_data.month < 4) ? param_year - 1 : param_year, params_data.month) && gstr3BPaths.includes(location.pathname)) {
      history.push(`${BASE_PATH}company/dashboard?company_id=${company_id}&year=${param_year}`);
      setError(`For ${headerData.gst_number}, GSTR 3B is not Applicable for the period ${moment(params_data.year + '-' + params_data.month + '-01').format("MMMM")} -${params_data.year}`);
    }

  }

  const checkMonthFilterActive = (returnFillingData, fy, month) => {
    let year = parseInt(fy);

    if (parseInt(month) < 4) { year = parseInt(fy) + 1; }

    if (year > CURRENT_YEAR) { return false; }

    if (year === CURRENT_YEAR && month > CURRENT_MONTH) { return false; }

    if (gstr1Paths.includes(location.pathname) && (year >= 2021 || (returnFillingData[year] && returnFillingData[year][month] && returnFillingData[year][month].gstr1_applicable))) {
      return true;
    }
    else if (iffPaths.includes(location.pathname) && year >= 2021) {
      return true;
    }
    else if (iffPaths.includes(location.pathname) && (returnFillingData[year] && returnFillingData[year][month] && returnFillingData[year][month].gstr1_applicable)) {
      return true;
    }
    else if (gstr3BPaths.includes(location.pathname) && returnFillingData[year] && returnFillingData[year][month] && returnFillingData[year][month].gstr3b_applicable) {
      return true;
    }
    else if (gstr1aPaths.includes(location.pathname) && returnFillingData[year] && returnFillingData[year][month] && returnFillingData[year][month].gstr1a_applicable) {
      return true;
    }
    else if (monthDropDown && returnFillingData[year] && !returnFillingData[year][month]) {
      return true;
    }
    else if (recoDashboardpath.includes(location.pathname)) {
      return true;
    }
    else {
      return false;
    }
  }

  const getGstrFilledStatus = () => {

    let year = filterYear ? parseInt(filterYear.value) : 0;
    let month = filterMonth ? parseInt(filterMonth.value) : 0;

    if (!year || !month) { return false; }

    if (month < 4) { year = year + 1; }

    if (gstr1Paths.includes(location.pathname) && iffPaths.includes(location.pathname) && returnFillingStatus[year] && returnFillingStatus[year][month] && returnFillingStatus[year][month].gstr1_filed) {
      return (<>
        <span style={{ padding: '10px 7px 6px 11px', color: '#fc7d72' }}>|</span>
        <span className="gstrdatebox">
          <span style={{ paddingRight: '0px', color: '#31719b' }}>Filed On: {returnFillingStatus[year][month].gstr1_filing_date}</span>
        </span>
      </>)
    }
    else if (gstr3BPaths.includes(location.pathname) && returnFillingStatus[year] && returnFillingStatus[year][month] && returnFillingStatus[year][month].gstr3b_filed) {
      return (<>
        <span style={{ padding: '10px 7px 6px 11px', color: '#fc7d72' }}>|</span>
        <span className="gstrdatebox">
          <span style={{ paddingRight: '0px', color: '#31719b' }}>Filed On: {returnFillingStatus[year][month].gstr3b_filing_date}</span>
        </span>
      </>)
    }
    else if (gstr9Paths.includes(location.pathname) && returnFillingStatus[year + 1] && returnFillingStatus[year + 1]["3"] && returnFillingStatus[year + 1]["3"].gstr9_filed) {
      return (<>
        <span style={{ padding: '10px 7px 6px 11px', color: '#fc7d72' }}>|</span>
        <span className="gstrdatebox">
          <span style={{ paddingRight: '0px', color: '#31719b' }}>Filed On: {returnFillingStatus[year + 1]["3"].gstr9_filing_date}</span>
        </span>
      </>)
    }
    else if (gstr9CPaths.includes(location.pathname) && returnFillingStatus[year + 1] && returnFillingStatus[year + 1]["3"] && returnFillingStatus[year + 1]["3"].gstr9c_filed) {
      return (<>
        <span style={{ padding: '10px 7px 6px 11px', color: '#fc7d72' }}>|</span>
        <span className="gstrdatebox">
          <span style={{ paddingRight: '0px', color: '#31719b' }}>Filed On: {returnFillingStatus[year + 1]["3"].gstr9c_filing_date}</span>
        </span>
      </>)
    }
    else if (gstr1Paths.includes(location.pathname) && returnFillingStatus[year] && returnFillingStatus[year][month] && returnFillingStatus[year][month].gstr1_filed && returnFillingStatus[year][month].gstr1_applicable) {
      return (<>
        <span style={{ padding: '10px 7px 6px 11px', color: '#fc7d72' }}>|</span>
        <span className="gstrdatebox">
          <span style={{ paddingRight: '0px', color: '#31719b' }}>Filed On: {returnFillingStatus[year][month].gstr1_filing_date}</span>
        </span>
      </>)
    }
    else if (iffPaths.includes(location.pathname) && returnFillingStatus[year] && returnFillingStatus[year][month] && returnFillingStatus[year][month].iff_applicable && returnFillingStatus[year][month].gstr1_filed) {
      return (<>
        <span style={{ padding: '10px 7px 6px 11px', color: '#fc7d72' }}>|</span>
        <span className="gstrdatebox">
          <span style={{ paddingRight: '0px', color: '#31719b' }}>Filed On: {returnFillingStatus[year][month].gstr1_filing_date}</span>
        </span>
      </>)
    }
    else if (gstr1Paths.includes(location.pathname) || iffPaths.includes(location.pathname) || gstr3BPaths.includes(location.pathname) || gstr9Paths.includes(location.pathname) || gstr9CPaths.includes(location.pathname)) {
      return (<>
        <span style={{ padding: '10px 7px 6px 11px', color: '#fc7d72' }}>|</span>
        <span className="gstrdatebox">
          <span style={{ paddingRight: '0px', color: '#31719b' }}>Not Filed</span>
        </span>
      </>)
    }

    return false;

  }

  const itemRender = (li, itemProps) => {
    let year = filterYear.value;
    let month = itemProps.dataItem.value;

    const itemChildren = (
      <span onClick={() => { handleMonthFilterChange(itemProps.dataItem.value, itemProps.dataItem); }} className={checkMonthFilterActive(returnFillingStatus, year, month) ? '' : 'disabledoption'}>
        {li.props.children}
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);

  };

  const handleMonthFilterChange = (month, value) => {
    let year = filterYear.value;
    // let month = e.target.value.value;
    if (checkMonthFilterActive(returnFillingStatus, year, month)) {
      // changeFilter(e.target.value, 'month');
      changeFilter(value, 'month');
      setMonthDropDownPopup(false);
      $('#yearDropDownPopup').focus();
    }
  }


  useEffect(() => {
    var previousScroll = 0;
    $(window).scroll(function () {
      var currentScroll = $(this).scrollTop();
      if (currentScroll > 0 && currentScroll < $(document).height() - ($(window).height() + $("#header").height())) {
        if (currentScroll > previousScroll) {
          $("#header").removeClass('header-show').addClass("header-hidden");
        } else {
          $("#header").removeClass("header-hidden").addClass("header-show");
        }
        previousScroll = currentScroll;
      }
      else if (currentScroll === 0) {
        $("#header").removeClass("header-hidden").addClass("header-show");
      }
    });

  }, [headerData.gst_number, company_id]);

  useEffect(() => {
    if (headerData.gst_number && company_id && !nonCompanySyncPaths.includes(location.pathname)) {
      getReturnFillingStatusData(headerData.gst_number, company_id);
    }
    //eslint-disable-next-line
  }, [headerData.gst_number, company_id, params.year, params.month]);

  useEffect(() => {
    setCallMe(headerData.mobile_number);
  }, [headerData]);

  useEffect(() => {
    if (headerData?.mobile_number && !headerData.plan_valid) {
      setShowSubscribePopup(true);
      $(document).ready(function () {
        setTimeout(() => {
          $('.planexpiredalert').click(function () {
            setShowSubscribePopup(true);
            return false
          });
        }, 500);

      });
    }
    //eslint-disable-next-line
  }, [headerData?.mobile_number, headerData?.plan_valid, location.pathname])


  const getStorageKey = (mobile_number, gst_number) => {
    return `storageKey_${mobile_number}_${gst_number}`;
  };

  useEffect(() => {
    setInitialRender(true);
    if (recoStatusBarShowPath.includes(location.pathname) && location?.pathname !== savePathName && params?.company_id) {
      setSavePathName(location?.pathname ?? "");
      getRecoSyncStatusOnServer();
    }
    //eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (!recoDashboardpath.includes(location.pathname)) return;

    const urlParams = queryString.parse(location.search);
    if (headerData.mobile_number && headerData.gst_number) {
      const key = getStorageKey(headerData.mobile_number, headerData.gst_number);

      let localEncodedParams = urlParams.encodedParams || localStorage.getItem(key) || return_financial_year;

      const { selectedYears: decodedYears, selectedMonths: decodedMonths } = localEncodedParams
        ? decodeParamsHeaderMulti(localEncodedParams)
        : { selectedYears: [], selectedMonths: [] };

      const currentFinancialYear = return_financial_year || year;
      let yearsToSet = decodedYears;

      if (!decodedYears.length && currentFinancialYear) {
        yearsToSet = [currentFinancialYear];
      }
      setSelectedYears(yearsToSet);

      const newMonths = getFinancialMonthsReco(yearsToSet).map(month => ({
        ...month,
        checked: decodedMonths.some(decodedMonth => decodedMonth.text === month.text),
      })).filter(month => !month.disable);

      setMonthBasedYear(newMonths);

      if (!urlParams.encodedParams) {
        localEncodedParams = encodeParamsHeaderMulti(yearsToSet, decodedMonths);
        const newUrlParams = { ...urlParams, encodedParams: localEncodedParams };
        const newSearchString = queryString.stringify(newUrlParams);
        history.replace(`${location.pathname}?${newSearchString}`);
      } else {
        // setEncodedParams(localEncodedParams);
      }
    }
    //eslint-disable-next-line
  }, [location.search, return_financial_year, year, getFinancialMonthsReco, history, headerData.mobile_number, headerData.gst_number]);


  useEffect(() => {
    if (!recoDashboardpath.includes(location.pathname)) {
      return;
    }
    const key = getStorageKey(headerData.mobile_number, headerData.gst_number);
    if (!localStorage.getItem(key) && params.encodedParams) {
      localStorage.setItem(key, params.encodedParams);
    }
    //eslint-disable-next-line
  }, [params.encodedParams, headerData.mobile_number, headerData.gst_number]);

  useEffect(() => {

    if (params.encodedParams && headerData.gst_number) {
      const key = getStorageKey(headerData.mobile_number, headerData.gst_number);
      localStorage.setItem(key, params.encodedParams);
    }
  }, [params.encodedParams, headerData.mobile_number, headerData.gst_number]);

  const handleCheckboxChange = (selectedMonth) => {
    const updatedMonths = monthBasedYear.map(month => {
      if (month.text === selectedMonth.text) {
        return { ...month, checked: !month.checked };
      }
      return month;
    });

    setMonthBasedYear(updatedMonths);

    const checkedMonths = updatedMonths.filter(month => month.checked);

    const newParams = { ...queryString.parse(location.search) };
    const newEncodedParams = encodeParamsHeaderMulti(selectedYears, checkedMonths);
    newParams.encodedParams = newEncodedParams;
    // setEncodedParams(newEncodedParams);

    const newSearch = queryString.stringify(newParams);
    history.replace({
      pathname: location.pathname,
      search: newSearch,
    });
  };


  const handleYearClick = (yearValue) => {
    setSelectedYears(prevYears => {
      const yearExists = prevYears.includes(yearValue);
      let newSelectedYears;

      // Check if the organization type is not CORPORATE
      if (headerData.organization_type !== 'CORPORATE') {
        // If the selected year already exists, return the existing years
        if (yearExists) {
          return prevYears;
        }
        // Otherwise, return an array with only the selected year
        newSelectedYears = [yearValue];
      } else {
        // Existing logic for CORPORATE
        if (yearExists) {
          // If this is the only selected year, don't allow unchecking
          if (prevYears.length === 1) {
            return prevYears;
          }
          newSelectedYears = prevYears.filter(year => year !== yearValue);
        } else {
          newSelectedYears = [...prevYears, yearValue];
        }

        if (newSelectedYears.length > 1) {
          newSelectedYears = newSelectedYears.sort((year1, year2) => parseInt(year2, 10) - parseInt(year1, 10));
        }
      }

      // Update months and encoded params
      const months = getFinancialMonthsReco(newSelectedYears).map(month => ({
        ...month,
        checked: monthBasedYear.some(existingMonth => existingMonth.text === month.text && existingMonth.checked),
      }));

      setMonthBasedYear(months);

      const newParams = { ...queryString.parse(location.search) };
      const newEncodedParams = encodeParamsHeaderMulti(newSelectedYears, months.filter(month => month.checked));
      newParams.encodedParams = newEncodedParams;

      const newSearch = queryString.stringify(newParams);
      history.replace({
        pathname: location.pathname,
        search: newSearch,
      });

      // Update local storage
      if (headerData.mobile_number && headerData.gst_number) {
        const key = getStorageKey(headerData.mobile_number, headerData.gst_number);
        localStorage.setItem(key, newEncodedParams);
      }

      return newSelectedYears;
    });
  };
  const getYearLabel = (year) => {
    return `${year}-${(year + 1).toString().substr(2, 2)}`;
  };

  const messageBarCenterPosition = (screenWidth) => {
    if (screenWidth && recoSyncStatus) {
      if (recoSyncStatus === "IN_PROGRESS") {
        return `${((screenWidth / 2) - (603 / 2))}px`;
      } else if (recoSyncStatus === "COMPLETE") {
        return `${((screenWidth / 2) - (458 / 2))}px`;
      }
    }
    return "34.5%"
  }

  return (
    <header id="header">

      {loading ? <LoadingIndicator /> : null}

      <ShowAlert
        autoHide={error ? false : true}
        success={success}
        error={error}
        onClose={() => { setError(null); setSuccess(null); }}
      />

      {!planNotShowPath.includes(location.pathname) && <SubscribePopup
        show={showSubscribePopup}
        handleClose={(msg = "") => {
          setShowSubscribePopup(false);
          if (msg) {
            setSuccess(msg);
          }
        }}
        history={history}
      />}

      {
        (showSyncVerify && start_gstr1_upload === 0 && gstr1_filing_path === false && company_id && headerData.plan_valid)
          ? (
            <SyncData
              show={showSyncVerify}
              handleClose={() => { checkTourGuide(); setShowSyncVerify(false); }}
              company_id={company_id}
              year={filterYear && filterYear.id ? filterYear.id : null}
              refreshSupplierList={() => getSyncStatus(company_id)}
              syncStatusFailureMsg={syncStatusFailureMsg}
              syncStatusRemindLater={syncStatusRemindLater}
              companyGSTNumber={headerData.gst_number}
            />
          )
          : null
      }

      {
        showGstInfoPopup
        && (
          <GstInfoAnimation
            gstInfo={gstInfo}
            headerData={headerData}
            showGstInfoPopup={showGstInfoPopup}
            onHide={() => { setShowGstInfoPopup(false); }}
            loading={100}
          />
        )
      }

      {
        showGstInModel
        && (
          <GstinInfoModel
            show={showGstInModel}
            handleClose={() => {
              setShowGstInModel(false);
            }}
            data={gstInData}
            setData={setGstInData}
            headerData={headerData}
            company_id={company_id}
            gstInNumber={sendGstNumber}
            setLoading={setLoading}

          />
        )
      }

      <nav className={`navbar ${!simpleHeaderPaths.includes(location.pathname) && !location.pathname.includes('update-company') && !location.pathname.includes('return-register') ? 'comp_header_mobile_width' : 'header_mobile_width'}`}>
        <div className="container-fluid">

          {/* left side col-6 */}
          <div className="navbar-header logo_width_mobile30 col-md-4 col-sm-4 col-lg-5 col-xl-5 col-12"
          >
            <div className="headerlogodispinle">
              <Link className="navbar-brand mainexplogo" to={`${BASE_PATH}`}>
                {
                  logo_touse === ExgstDiwalilogo
                    /* For object tag, need to set pointer-events: none for clickable link. Ref: https://stackoverflow.com/a/17133804/2469308 */
                    ? <object style={{ pointerEvents: "none" }} type="image/svg+xml" data={ExgstDiwalilogo} aria-labelledby="Express GST" width="120" height="45"></object>
                    : <img src={logo_touse} alt="Express GST" />
                }

              </Link>

              {!LOGIN_PATHS.includes(location.pathname) && (
                <ul className="compboxtoppadd">
                  <li>
                    {(headerData.company_name && !nonCompanyHeaderPaths.includes(location.pathname)) && (
                      <div
                        className="profilesmfsize"
                        style={{
                          textAlign: 'left', color: '#31719b', fontSize: '13px',
                        }}
                      >

                        <div  style={{ position: 'relative', display: 'inline-block', fontWeight: '700' }}>
                          <span className='gst_leftcomp_overflowtet'>
                            <span data-toggle="tooltip" data-placement="top" title= {headerData.company_name}>
                              {headerData.company_name}
                            </span>
                          </span>
                        </div>
                        <br />
                        <div className="infoicongstin gstin_insight planexpiredalert" onClick={() => getGSTInfoData(headerData.gst_number)}>
                          <span data-toggle="tooltip" data-placement="top" title="GSTIN Insights">
                            {headerData.gst_number}
                            <span className="gstinfoiconheader">
                              <FontAwesomeIcon icon={faInfoCircle} />
                            </span>
                          </span>
                        </div>
                        <span style={{ padding: '10px 7px 6px 11px', color: '#fc7d72' }}>|</span>
                        {headerData.registration_type}
                        {location.pathname === `${BASE_PATH}company/dashboard` &&
                          <>
                            {/*<span style={{ padding: '10px 7px 6px 11px', color: '#fc7d72' }}>|</span>
                           <img id="intro_icon" src={screenguidicon} width="18" style={{ cursor: 'pointer' }} />
                            */}
                          </>
                        }
                        <span style={{ padding: '10px 7px 6px 5px', color: '#fc7d72' }}>|</span>
                        <span className='pl-1 gst_portal_login'>
                          <span data-toggle="tooltip" data-placement="top" title="Login at GST Portal">
                            <GstPortalLoginConnector companyId={company_id} />
                          </span>
                        </span>
                      </div>
                    )}
                  </li>
                </ul>)}
            </div>
          </div>
          {/* end left side col-6 */}
          {/* right side col-6 */}

          {!LOGIN_PATHS.includes(location.pathname) && (
            <div
              className=" col-md-8 col-sm-8 col-lg-7 col-xl-7 col-12 mb-0 pb-0 urs_width_mobile70" >
              <div className="dispinlinerightnav">
                <ul className="headernavdropmd mb-0 pb-0" style={{ minHeight: '47px' }}>
                  {(supplierPaths.includes(location.pathname) || recoDashboardpath.includes(location.pathname) || gstr1Paths.includes(location.pathname) || iffPaths.includes(location.pathname) || gstr3BPaths.includes(location.pathname) || gstr9Paths.includes(location.pathname) || gstr9CPaths.includes(location.pathname)) && (
                    <li className="headullipaddreight">
                      <div
                        style={{
                          textAlign: 'right', color: 'rgb(252 125 114)', fontSize: '12px', fontWeight: '600', marginTop: '5px', paddingRight: '0rem'
                        }}
                      >
                        <div className="taxtoppadd">
                          <span className="aggregate_turn_over">
                            <span className="mytunrovertext">
                              <span className="headerfy_overflowtext">
                                <span data-toggle="tooltip" data-placement="top" title="Aggregate Turnover of FY 2020-21 > 5 CR ?">
                                  Aggregate Turnover of FY 2020-21 {'>'} 5 CR ?
                                </span>

                              </span>
                            </span>

                            <span style={{ padding: '10px 22px 6px 5px', color: '#fc7d72' }}></span>
                            <span className="headertogglebtn">
                              <label className="switch htoggleswit">
                                <input type="checkbox" className='planexpiredalert' checked={!headerData.turnover_above_5cr} onChange={() => toggleCompanyTurnoverData()} />
                                <span className="slider round"></span>
                              </label>
                            </span>
                          </span>

                          <span style={{ padding: '10px 7px 6px 11px', color: '#fc7d72' }}>|</span>

                          {recoDashboardpath.includes(location.pathname) ? (
                            <span style={{ textAlign: 'right', color: 'rgb(252, 125, 114)', fontSize: '12px', fontWeight: '600', marginTop: '0px', paddingRight: '0rem' }}>
                              <span className="taxtoppadd">
                                <span className="dropdown partywise_reco_perioyr_dropbox partywisereco_headerdroplist_yrwidth mr-1">
                                  <span type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                                    {/* {selectedYears.length > 1 ? `${selectedYears.length} FY Selected` : `${selectedYears.length} Year${selectedYears.length > 1 ? 's' : ''}`} */}
                                    {selectedYears.length > 1 ? `${selectedYears.length} FY(s) Selected` : `FY(s) ${getYearLabel(parseInt(selectedYears[0]))}`}
                                  </span>
                                  <span className="dropdown-menu partywise_reco_perioyr_dropboxmenu yearsseldropbox_width">
                                    {getFinancialYears(headerData.registration_date).map((year, index) => (
                                      <div key={year.value}>
                                        <div className="dropdown-item">
                                          <input
                                            type="checkbox"
                                            className="form-control multiselbox_inputbox"
                                            checked={selectedYears.includes(year.value)}
                                            onChange={() => handleYearClick(year.value)}
                                          />
                                          <span className="fytext_fs"> FY {year.text}</span>
                                        </div>
                                        <div className="dropdown-divider"></div>
                                      </div>
                                    ))}
                                  </span>
                                </span>

                                <span className="dropdown partywise_reco_perioyr_dropbox partywisereco_headerdroplist_monthwidth mr-1">
                                  <span type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                                    {monthBasedYear.filter(month => month.checked).length === 0 ? 'Month(s) Selected' : `${monthBasedYear.filter(month => month.checked).length} Month(s) Selected`}
                                  </span>
                                  <span className="dropdown-menu partywise_reco_perioyr_dropboxmenu">
                                    {monthBasedYear.map((month, index) => (
                                      <div key={index}>
                                        <div className="dropdown-item">
                                          <input
                                            type="checkbox"
                                            className="form-control multiselbox_inputbox"
                                            checked={month.checked}
                                            disabled={month.disable}
                                            onChange={() => handleCheckboxChange(month)}
                                          />
                                          <span className="fytext_fs">{month.text}</span>
                                        </div>
                                        <div className="dropdown-divider"></div>
                                      </div>
                                    ))}
                                  </span>
                                </span>
                              </span>
                            </span>
                          )
                            :
                            <>
                              <span style={{ paddingRight: '10px', color: '#ccc' }}>
                                {monthDropDown ? 'Tax Prd' : 'F.Y.'}
                              </span>
                              <span className="loaderboxlpad">
                                <DropDownList
                                  data={fyList}
                                  className="header_select headerselect"
                                  popupSettings={{ className: 'headerselectoption' }}
                                  textField="text"
                                  value={filterYear}
                                  onChange={(e) => { changeYearFilter(e.target.value); }}
                                  dataItemKey="value"
                                  id="yearDropDownPopup"
                                />
                              </span>
                              {monthDropDown
                                ? (
                                  <span id="headerMonthDropDown" style={{ paddingLeft: '10px', marginRight: '0px' }}>
                                    <DropDownList
                                      data={monthList}
                                      className="header_select headerselect monthelbox"
                                      popupSettings={{ className: 'headerselectoption', height: '250px' }}
                                      textField="month"
                                      value={filterMonth}
                                      id="monthDropDownPopup"
                                      opened={monthDropDownPopup}
                                      onChange={(e) => { handleMonthFilterChange(e.target.value.value, e.target.value); }}
                                      onBlur={() => { setMonthDropDownPopup(false); }}
                                      onFocus={() => { setMonthDropDownPopup(true); }}
                                      dataItemKey="value"
                                      itemRender={itemRender}
                                    // itemRender={(li, itemProps) => { return itemRender(li, itemProps); }}
                                    />
                                  </span>
                                ) : null}
                            </>}
                        </div>
                        {syncLoading && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            style={{ position: 'absolute', left: '-30%', right: '20rem', margin: '70% 0%  20% 0%' }}
                          />
                        )}

                        <span className="sync_status">
                          {!syncLoading
                            ? (
                              <span className="boxalign">
                                {syncStatusData.status === 'IN_PROGRESS' ? 'Sync started on: ' : ''}
                                {syncStatusData.status === 'SUCCESS' ? 'Last synced on: ' : ''}
                                {syncStatusData.status === 'FAILURE' ? 'Last sync failed on: ' : ''}
                                {!syncStatusData.status || syncStatusData.status === '' ? 'Sync Now' : ''}
                                {syncStatusData.updated_at}
                                <span
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={syncStatusData.status === 'IN_PROGRESS' ? "Data sync is in progress." :
                                    syncStatusData.status === 'SUCCESS' ? syncStatusData.updated_at_unixts && moment().diff(moment(moment.unix(syncStatusData.updated_at_unixts).format('DD-MM-YYY'), 'DD-MM-YYY'), 'days') === 0 ? "Portal data can be synced only once per day. You may re-sync data again tomorrow onwards." : "Refresh Portal Data" : "Refresh Portal Data"}
                                >
                                  {syncStatusData.status === 'IN_PROGRESS'
                                    ? <FontAwesomeIcon icon={faSync} className="refrsh_sync rotate_icon" />
                                    : <FontAwesomeIcon
                                      icon={faSync}
                                      className={syncStatusData.status === 'SUCCESS' && syncStatusData.updated_at_unixts && moment().diff(moment(moment.unix(syncStatusData.updated_at_unixts).format('DD-MM-YYY'), 'DD-MM-YYY'), 'days') === 0 ? "refrsh_sync disableRefrshSync planexpiredalert" : "refrsh_sync planexpiredalert"}
                                      onClick={() => {
                                        if (!(syncStatusData.status === 'SUCCESS' && syncStatusData.updated_at_unixts && moment().diff(moment(moment.unix(syncStatusData.updated_at_unixts).format('DD-MM-YYY'), 'DD-MM-YYY'), 'days') === 0)) {
                                          setShowSyncVerify(true);
                                        }
                                      }}
                                    />
                                  }
                                </span>
                              </span>
                            )
                            : null
                          }
                        </span>
                        {getGstrFilledStatus()}
                      </div>

                    </li>)}
                  <li className="dropdmenufr">

                    {!LOGIN_PATHS.includes(location.pathname) && (
                      <div className="userprodropd">
                        <ul className="">
                          {(headerData.company_name && !nonCompanyHeaderPaths.includes(location.pathname)) && (
                            <li className="nav-item dropdown bellaltlist  search-gst-icon" style={{ top: !simpleHeaderPaths.includes(location.pathname) && !location.pathname.includes('update-company') && !location.pathname.includes('return-register') ? '' : '' }}>
                              <span class="searchbox_icon" onClick={() => { setShowGstSerach(prev => !prev) }} style={{ cursor: "pointer", position: "relative", top: "2px", marginRight: "2px" }}>
                                <img className="" src={searchIcon} alt="search gstin" width={19} />
                              </span>
                              <div className={`dropdown-menu dropdown-menu-right gstrsearchboxinner ${showGstSearch ? 'show' : ''}`}>
                                <div class="dropdown-item ">
                                  <div class="form-group headewrhas-search">
                                    <div class="form-inline" autoComplete="off">
                                      <input class="form-control headersearchbox" type="text" placeholder="Search GSTIN" aria-label="Search"
                                        value={gstInNumber} maxLength={15} onChange={(e) => setGstInNumber(() => { return (e.target.value.toUpperCase()) })}
                                        onKeyDown={(e) => e.keyCode === 13 && searchGstIn(e.target.value)}
                                        ref={(input) => { input && input.focus() }}
                                      />
                                    </div>
                                    <span class="searchiconlistofcomp" style={{ top: 0, left: 0 }} data-toggle="modal" data-target="#gstin_headersearchbox_modalpopup"
                                      onClick={() => searchGstIn(gstInNumber)}
                                    >
                                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" class="svg-inline--fa fa-search fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z" width="15"></path>
                                      </svg>
                                    </span>
                                  </div>

                                </div>
                              </div>
                            </li>
                          )}
                          <li className="nav-item dropdown bellaltlist" style={{ top: !simpleHeaderPaths.includes(location.pathname) && !location.pathname.includes('update-company') && !location.pathname.includes('return-register') ? '' : '' }}>
                            <Notifications />
                          </li>
                          <li className="nav-item dropdown userprodropdlist" style={{ top: !simpleHeaderPaths.includes(location.pathname) && !location.pathname.includes('update-company') && !location.pathname.includes('return-register') ? '' : '' }}>
                            <a className="nav-link dropdown-toggle" href="#/" id="navbardrop" data-toggle="dropdown">
                              <span className="profile_img_bg dark_color_text">
                                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-person-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                  <path fillRule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                </svg>
                              </span>
                            </a>

                            <div className="dropdown-menu dropdown-menu-right">
                              <div className="dropdown-item profileusr" to={BASE_PATH}>

                                <span className="black_color_text usertextoverflow"
                                  style={{
                                    fontSize: '12px',
                                    padding: '0rem',
                                    marginTop: '0rem',
                                    borderRadius: '0rem'
                                  }}>
                                  <span className="pl-0" data-toggle="tooltip" data-placement="top" title={headerData.full_name}>
                                    {headerData.full_name}
                                  </span>

                                </span>
                                <span className="activeusrp">
                                  <FontAwesomeIcon icon={faCircle} />
                                </span>

                              </div>
                              <div className="dropdown-divider" />

                              <Button
                                className="dropdown-item"
                                onClick={() => {
                                  history.push(`${BASE_PATH}e-invoice`);
                                }}
                                style={{ fontSize: '12px', padding: '0.2rem 0.5rem' }}
                              >
                                e-Inovice
                              </Button>
                              <div className="dropdown-divider" />
                              {/* {
                                headerData && headerData?.organization_type === "CORPORATE" &&
                                <>
                                  <div
                                    className="dropdown-item"
                                    title='Comming Soon !'
                                    onClick={(e) => {
                                      e.preventDefault(); // Prevents navigation
                                      e.stopPropagation();
                                    }}
                                    style={{ fontSize: '12px', padding: '0.2rem 0.5rem', cursor: 'pointer', color: "#9c9c9c" }}
                                    disabled={true}
                                  >
                                    e-Invoice
                                  </div>
                                  <div className="dropdown-divider" />
                                </>
                              } */}


                              <Button
                                className="dropdown-item"
                                onClick={() => {
                                  history.push(`${BASE_PATH}profile`);
                                }}
                                style={{ fontSize: '12px', padding: '0.2rem 0.5rem' }}
                              >
                                Profile Settings
                              </Button>
                              <div className="dropdown-divider" />
                              <Button
                                className="dropdown-item"
                                onClick={() => {
                                  history.push(`${BASE_PATH}subscription`);
                                }}
                                style={{ fontSize: '12px', padding: '0.2rem 0.5rem' }}
                              >
                                Subscription Plan
                              </Button>

                              {(headerData.user_role.toUpperCase() === 'OWNER') && (
                                <>
                                  <div className="dropdown-divider" />
                                  <Button
                                    className="dropdown-item planexpiredalert"
                                    onClick={() => {
                                      history.push(`${BASE_PATH}user-management`);
                                    }}
                                    style={{ fontSize: '12px', padding: '0.2rem 0.5rem' }}
                                  >
                                    User Management
                                  </Button>
                                </>
                              )}

                              <div className="dropdown-divider" />
                              <a href="https://www.youtube.com/watch?v=ome0q1JJk40&list=PLMMU4MgWazh0A0amiOOPyZZsAt-scCUFu&index=1" target="_blank" rel="noopener noreferrer" className='headerdropd_helpv_fs'>
                                <Button
                                  className="dropdown-item"
                                  style={{ fontSize: '12px', padding: '0.2rem 0.5rem' }}
                                >
                                  Tutorial Video
                                </Button>
                              </a>


                              {/* {(headerData.organization_type === 'CA') && (
                                <> */}
                              <div className="dropdown-divider" />
                              <Button
                                className="dropdown-item planexpiredalert"
                                onClick={() => {
                                  history.push(`${BASE_PATH}schedule-2a-2b-reco-email?sort_on=trade_name&order_by=asc`);
                                }}
                                style={{ fontSize: '12px', padding: '0.2rem 0.5rem' }}
                              >
                                {/* Schedule 2A / 2B Email */}
                                Client Settings
                              </Button>
                              {/* </>
                              )} */}


                              {tourPaths.includes(location.pathname) &&
                                <>
                                  <div className="dropdown-divider" />
                                  <Button
                                    className="dropdown-item"
                                    id="intro_icon"
                                    style={{ fontSize: '12px', padding: '0.2rem 0.5rem' }}
                                  >
                                    Guided Tour
                                  </Button>
                                </>
                              }

                              <div className="dropdown-divider" />
                              <Button className="dropdown-item logdisinline" id="zps_logout_button" onClick={(e) => logOut(e)} style={{ fontSize: '12px' }}>
                                Logout
                                <span className=" logicntopm">
                                  <img className="" src={logouticon} alt="logout" />
                                </span>
                              </Button>

                            </div>
                          </li>
                        </ul>
                      </div>
                    )}
                  </li>
                </ul>

                {/* Start call me */}
                <ul className="callmerightbox">
                  <li className="nav-item dropdown dropleft callmemainbox " style={{ top: !simpleHeaderPaths.includes(location.pathname) && !location.pathname.includes('update-company') ? '' : '' }}>
                    <span className="nav-link-btn callmebgclr planexpiredalert" data-toggle="dropdown">
                      <span className="callicon planexpiredalert"><img src={callIcon} alt="call" /></span>
                      <span className="calltext planexpiredalert" id="zps_call_me_valid_plan">Call Me</span>
                    </span>

                    <div className="dropdown-menu cllmeinnerdown">
                      <div className="dropdown-item headerscetbborder">
                        <span className="callicon"><img src={callblueIcon} alt="call" /></span>
                        <span className="headertextclr"> Get a Call Back from Us </span>
                        <span className="closeiconright">
                          <i className="fa fa-times" aria-hidden="true"></i>
                        </span>
                      </div>
                      <div className="textboxinnersect">
                        <p className="contentarea">
                          Enter the Mobile No. on which you want to get a call back from us:
                        </p>
                        <div className="inputboxarea">
                          <NumericInput
                            type="text"
                            className="form-control inputcallme"
                            placeholder="Enter Mobile Number"
                            value={callMe}
                            maxLength={10}
                            onChange={(e) => setCallMe(e.target.value)}
                            id="call_me_input"
                          />
                        </div>
                        <div className="callmebtnbox">
                          <button className="btn btn-default callmedonebtn" onClick={() => addCallMeData()}> Done</button>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                {/* End call me */}
              </div>

            </div>)}
          {/* right side col-6 */}
        </div>
      </nav>
      {recoStatusBarShowPath.includes(location.pathname) && recoSyncStatus && 
      <DraggableContent style={{position: 'fixed', zIndex: 100, bottom: '0px', left: `${messageBarCenterPosition(windowWidth)}`, maxHeight: 'fit-content'}}>
          <div className="sync_stripchallanfooter d-flex justify-content-center ">            
            <span className="text-center drc-border-anibar">            
              <span className='exprit_expriebox_btnboxsss'>
                <span style={{ paddingLeft: '5px', left:'-4px', position:'relative', paddingTop: '0px', color: '#fc7d72'}}></span>
                <span className=" ml-2">           
                  {recoSyncStatus === "IN_PROGRESS" ? <span>{recoInProgressMessage}<FontAwesomeIcon icon={faSync} className="refrsh_sync rotate_icon" style={{zIndex: "100", width: '1.1rem'}} /> </span> 
                  : recoSyncStatus === "COMPLETE" ? <span>
                    <img className='barclsiconrefresh' width="20px" src={closeIcon} alt="" onClick={() => history.go()} />
                    {recocompletedMessage}</span> :  null}      
                </span>
              </span>
              <svg><defs><filter id="glow"></filter></defs><rect className="react_attribute"></rect></svg>
            </span>
          </div>
        </DraggableContent>}
    </header >
  );
};

const HeaderWrapper = withRouter(Header);
export default HeaderWrapper;