export const API_BASE_URL = "https://appdev552.gstsoftware.com";
export const SITE_BASE_PATH = "/";
export const SITE_LOGO = "Exgst";

// For Connector
export const CFG_CONNECTOR_RELEASED_VERSIONS = { cv: 30, sv: 30 };
export const CFG_CONNECTOR_DOWNLOAD_LINK = "https://express-v2.s3.ap-south-1.amazonaws.com/connector/ExpressConnector-2.0/win_32/ExpressConnector-2-win32-v002.exe";
export const CFG_CONNECTOR_FAQ_LINK = "";

// For Maintenance mode
export const IN_MAINTENANCE_MODE = false;
export const MAINTENANCE_MESSAGE = "We are doing important maintenance work on the website and will be back shortly.<br /> We apologise for any inconvenience caused. If you need to get in teuch with us, Please use one of the options belows."

// Cobrand related
export const DOMAIN_COBRAND_MAPPING = [
    {
        id: 1,
        domain: "gstsoftware.com",
        sub_domain: "dev552",
        site_name: "Express GST",
        api_base_url: "https://appdev552.gstsoftware.com",
        site_base_path: "/",
        site_logo: "EXGST"
    },
    {
        id: 2,
        domain: "gstsoftware.com",
        sub_domain: "align552",
        site_name: "Align Books",
        api_base_url: "https://appdev552.gstsoftware.com",
        site_base_path: "/",
        site_logo: "ABV1"
    },
    {
        id: 3,
        domain: "gstsoftware.com",
        sub_domain: "align553",
        site_name: "Align Books",
        api_base_url: "https://appdev552.gstsoftware.com",
        site_base_path: "/",
        site_logo: "ABV2"
    }
];
export const EVC_FILED_ALLOWED = ['9413602676', '8890887845',];

//Portal data syncing status - 0 [only server], 1 [connector if available], 2 [only connector]
export const PORTAL_SYNCING_STATUS = 2;
export const API_BASE_URL_GO = "https://goappdev552.gstsoftware.com";

//For Tally
export const CFG_TALLY = {
    // For auto sync, if the current system time is between the start and end, then the sync process will start, if auto is enabled.
    DAILY_TALLY_SYNC_TIME: { start_hour: 9, start_min: 30, end_hour: 20, end_min: 30 },

    // For auto sync, first check in local storage, and if last sync time found, then we should wait for following minutes, for next auto sync to run
    LOCAL_CHECK_TALLY_SHOULD_SYNC_AFTER_MINTUES: 1440, // 1 day = 1440 minutes

    // For auto sync, second check in API, and if last sync time found, then we should wait for following minutes, for next auto sync to run
    API_CHECK_TALLY_SHOULD_SYNC_AFTER_MINTUES: 1440, // 1 day = 1440 minutes

    // Show offline Tally companies for the current system, found upto following number of days
    TALLY_DELETE_DAYS: 30,

    // On login, show Tally sync popup for first time only, upto the following date
    TALLY_ENABLE_NOW_POPUP_LAST_DATE: "2024-01-31"
}

export const CFG_PASSWORD_ENCRYPTION_KEY = 'D)TW:83yDo-|QR~z+jzd_u-50!?1ASr6';
export const CFG_PASSWORD_ENCRYPTION_IV = '1234567812345678';
