import React, { useEffect, useRef, useState } from 'react'
import { ProgressBar } from 'react-bootstrap';
import ConnectorPopup from './ConnectorPopup';
import { getRunningConnectorUsability, tallySyncUpdateLocalStorage } from '../../utils/UtilityFunctions';
import { getTallyStatus } from '../../api/localConnectorAPI';
import '../../features/gstr9/itc/reports/Reports.css'
import WindowWrapperFixWidth from '../gstr9/sales/reports/WindowWrapperFixWidth';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const TallyProgressBar = (props) => {
    const { gstNo, mobileNo, setTallySyncInProgress, setShowTallyProgress } = props
    const timer = useRef(null);
    const timerSync = useRef(null);
    const history = useHistory();
    const [clientImportProgress, setClientImportProgress] = useState(false)
    const [connectorPopup, setConnectorPopup] = useState(false);
    const [connectorStatus, setConnectorStatus] = useState(0);
    const [progressData, setProgressData] = useState({})
    const [pbVal, setPbVal] = useState(0)
    const [syncFailed, setSyncFailed] = useState(false)
    const [syncProgress, setSyncProgress] = useState(true)
    const [tallySyncComplete, setTallySyncComplete] = useState(false)

    const runConnectorWhenFound = async (processId) => {
        // Run a continuous loop every 2 seconds
        // Keep finding running Connector details - until we are in success or updatable scenario
        const runningConnectorResult = await getRunningConnectorUsability();

        let connectorRunning = false;
        if (runningConnectorResult.port === 0) {
            // Connector is not running or not installed yet. Need to install or Start.
            setConnectorStatus(2);
            connectorRunning = false;
        } else if (runningConnectorResult.install === 1) {
            // Connector is installed, but stale version. Need fresh install.
            setConnectorStatus(3);
            connectorRunning = false;
        } else if (runningConnectorResult.update === 1) {
            // Connector is installed, has old version and can be updated. However, work can still go on.
            setConnectorStatus(4);
            connectorRunning = true;
        } else {
            // Connection success and Connector is up to date. All good
            setConnectorStatus(1);
            connectorRunning = true;
        }
        if (connectorRunning === true && runningConnectorResult.install === 0) {
            runTallySyncProgressStatus(runningConnectorResult.port, processId)
            setTimeout(() => {
                setConnectorPopup(false)
            }, 0)
            return;
        }
        // Connector Still not Running. Run again after 2 seconds
        timer.current = setTimeout(() => runConnectorWhenFound(processId), 2000);
    }

    const runTallySyncProgressStatus = async (port, processId) => {
        if (!port) {
            return false;
        }
        try {
            const result = await getTallyStatus(port, processId);
            if (result?.data?.data?.process_status === 'IN_PROGRESS') {
                setProgressData(result?.data?.data?.pb_data ?? {})
                setPbVal(result?.data?.data?.pb_val ?? 0)
                timerSync.current = setTimeout(() => {
                    runTallySyncProgressStatus(port, processId);

                }, 5000);
            } else if (result?.data?.data?.process_status === 'COMPLETE') {
                setProgressData({ ...result?.data?.data?.pb_data, text: "Sync has been completed" })
                setPbVal(100)
                tallySyncUpdateLocalStorage(mobileNo, gstNo, result?.data?.data)
                setTallySyncInProgress && setTallySyncInProgress(false)
                setSyncProgress(false)
                setTallySyncComplete(true)
                setTimeout(() => {
                    history.go()
                }, (3000));
                // store.dispatch(setTallySyncStatus({ syncStarted: false }))

            } else if (result?.data?.data?.process_status === 'FAILURE') {
                setProgressData({ ...result?.data?.data?.pb_data, text: result?.data?.data?.pb_data?.error ?? "Sync has been failed" })
                tallySyncUpdateLocalStorage(mobileNo, gstNo, result?.data?.data)
                setTallySyncInProgress && setTallySyncInProgress(false)
                setSyncFailed(true)
                setSyncProgress(false)

            }
        } catch (err) {
            // Dont display error
            tallySyncUpdateLocalStorage(mobileNo, gstNo, { process_status: "FAILURE" })

        }
    }
    useEffect(() => {
        let localData = JSON.parse(localStorage.getItem("tally-sync-progress-" + mobileNo))
        if (localData && localData?.[gstNo]) {
            setConnectorPopup(true)
            runConnectorWhenFound(localData?.[gstNo])
            setClientImportProgress(true)
            setTallySyncInProgress && setTallySyncInProgress(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gstNo, mobileNo]);

    useEffect(() => {
        return () => {
            clearTimeout(timer.current);
            clearTimeout(timerSync.current);
        };
    }, [])

    return (
        <>
            {connectorPopup &&
                <ConnectorPopup
                    show={connectorPopup}
                    onClose={() => setConnectorPopup(false)}
                    connectorStatus={connectorStatus}
                />
            }
            <WindowWrapperFixWidth
                title={"Tally Sync Status"}
                onClose={() => { setTallySyncInProgress && setTallySyncInProgress(false); setShowTallyProgress(false) }}
                initialHeight={150}
                initialTop={500}
                initialWidth={500}
                initialLeft={200}
                showInCenter={true}
                isModalView={false}

            >
                {clientImportProgress ? <div className='processSync progress-sync-popup' style={{ cursor: 'pointer' }}>
                    <div className='client-status-show' style={{ color: '#196ec9', fontSize: '12px' }}>
                        Please wait while we are doing our magic to get data from Tally
                    </div>
                    {tallySyncComplete ? <ProgressBar striped now={pbVal} label={'Tally Data Sync has been Completed'} /> :
                        <ProgressBar animated now={pbVal} label={'Tally Data is Syncing...' + pbVal + ' %'} />}

                    <div className='client-status-show' style={syncFailed ? { color: "red" } : null}>
                        {syncProgress && "Sync in progress:"}
                        {progressData?.text ?? ""}</div>
                    {/* <div className='client-status-show'>Data Sync is in progress for: <span>{String(currentAssessObj?.full_name ?? '') ? currentAssessObj?.full_name + '-' + currentAssessObj?.pan : currentAssessObj?.pan}</span></div> */}
                    {/* <div className='client-status-show'>Time Remaining:<span>About {Math.max(Math.ceil((totalCount - currentWorking) * .1), 1)} {Math.max(Math.ceil((totalCount - currentWorking) * .1), 1) > 1 ? 'Minutes' : 'Minute'}  </span>   <div className='show-progress-count'>Count:<span>{currentWorking ?? 1}/{totalCount}</span></div></div> */}

                </div>
                    :
                    <div className='processSync progress-sync-popup' style={{ cursor: 'pointer' }}>
                        <div className='mt-2 text-center' style={{ color: '#196ec9', fontSize: '12px' }}>
                            No Sync is in Progress</div>
                    </div>

                }</WindowWrapperFixWidth>

        </>
    )
}

export default TallyProgressBar  