/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import NumericInput from "../../components/NumericInput";
import { selectHeaderData } from "../header/headerSlice";
import {
  get2ADataVerifyCatchaV2,
  verify2ADataCatcha,
} from "../../api/2adataAPI";
import UpdateCredentials from "./UpdateCredentials";
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import OtpVerifiyForCaptcha from "../../components/profile/OtpVerifiyForCaptcha";

const SyncData = ({ history, ...props }) => {
  const {
    company_id,
    year,
    show,
    handleClose,
    refreshSupplierList,
    syncStatusFailureMsg,
    syncStatusRemindLater,
    companyGSTNumber,
  } = props;

  const headerData = useSelector(selectHeaderData);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [captchaLoading, setCaptchaLoading] = useState(false);
  const [captchaId, setCaptchaId] = useState("");
  const [captchaImg, setCaptchaImg] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [openSetPassword, setOpenSetPassword] = useState(false);
  const [gstportalUsername, setGstportalUsername] = useState("");
  const [getCaptchaData, setGetCaptchaData] = useState({});
  const [loadingFirst, setLoadingFirst] = useState(true);
  const [otpRequired, setOtpRequired] = useState(false)

  const getCaptcha = () => {
    async function fetchEverything() {
      try {
        const result = await get2ADataVerifyCatchaV2(company_id);
        setCaptchaImg(result.data.captcha_image);
        setCaptchaId(result.data.gpc_id);
        setOpenSetPassword(!result.data.gstportal_isverified);
        // setOpenSetPassword(true);
        setGstportalUsername(result.data.gstportal_username);
        setGetCaptchaData(result.data);
        setCaptcha("");
      } catch (err) {
        console.error("error: ", err);
        setError(err.message);
      } finally {
        setCaptchaLoading(false);
        setLoadingFirst(false);
      }
    }
    setCaptchaLoading(true);
    fetchEverything();
  };

  const loadCaptcha = () => {
    setError(null);
    setCaptchaLoading(true);
    getCaptcha();
  };

  const verifyCatpcha = (props) => {
    if (!props?.otp) {
      if (captcha.length === 0) {
        setError("Please enter captcha!");
        return;
      }
      if (captcha.length < 6) {
        setError("Please enter valid captcha!");
        return;
      }
    }
    const data = {
      gpc_id: captchaId,
      ...(props?.otp ? { otp: props.otp } : { captcha_text: captcha }),
      company_id,
      priority: "GST2A",
      year,
    };

    async function fetchEverything() {
      async function fetch2AData() {
        const result = await verify2ADataCatcha(data);

        let return_filling_status_data = localStorage.getItem(
          "return-filling-status-data-v4"
        );
        return_filling_status_data = return_filling_status_data
          ? JSON.parse(return_filling_status_data)
          : [];
        let current_filling_return_data = return_filling_status_data.filter(
          (return_data, index) => {
            return return_data.gst_number !== headerData.gst_number;
          }
        );
        localStorage.setItem(
          "return-filling-status-data-v4",
          JSON.stringify(current_filling_return_data)
        );

        handleClose(false);
        refreshSupplierList(result.data.message, true);
      }
      try {
        await Promise.all([fetch2AData()]);
        setError(null);
        setOtpRequired(false)

      } catch (err) {
        if (err?.status === 423) {
          setOtpRequired(true)
        } else {
          handleClose()
        }
        if (err) {
          setError(err?.data?.errors[0])
        }
      } finally {
        setLoading(false);
      }
    }
    setLoading(true);
    fetchEverything();
  };

  const handleRemindLater = () => {
    localStorage.setItem(
      `remind_later_ts${companyGSTNumber}`,
      moment().add(1, "hours").unix()
    );
    handleClose(false);
  };

  const handleCloseOtp = () => {
    setOtpRequired(false);
    getCaptcha()
  }

  useEffect(() => {
    getCaptcha();
    document.getElementById("captcha").focus();
    if (syncStatusFailureMsg !== "") {
      setError(syncStatusFailureMsg);
    }
  }, [company_id]);

  return (
    <>
      {loadingFirst ? <LoadingIndicator /> : null}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="bookVerify bookVerify_modal"
      >
        {openSetPassword ? (
          <UpdateCredentials
            company_id={company_id}
            headerData={headerData}
            gstportalUsername={gstportalUsername}
            getCaptchaData={getCaptchaData}
            getCaptcha={getCaptcha}
            handleClose={handleClose}
            refreshSupplierList={refreshSupplierList}
            verifyCaptch={true}
          />
        ) : (
          <>
            <Modal.Header closeButton className="bookVerify">
              <Modal.Title>Refresh Portal Data</Modal.Title>
            </Modal.Header>
            <Modal.Body className="py-0 px-0">
              <div className="container mt-2">
                <div className="card bookVerifyCard">
                  <div className="row mt-0">
                    <div className="form-group col-md-12 mb-0">
                      <div className="row">
                        <div className="form-group col-md-6">
                          <div className="captcha_img">
                            {!captchaLoading ? (
                              <img
                                src={`data:image/png;base64, ${captchaImg}`}
                                alt=""
                              />
                            ) : (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}
                          </div>
                        </div>
                        <div
                          className="form-group col-md-6"
                          style={{ marginTop: "8px" }}
                        >
                          <NumericInput
                            className="form-control"
                            id="captcha"
                            placeholder="Enter Captcha"
                            value={captcha}
                            maxLength={6}
                            onChange={(event) => setCaptcha(event.target.value)}
                            autoComplete="new-password"
                          />
                        </div>
                      </div>
                      <div className="form-group col-md-12 mb-0">
                        <p className="mb-1">
                          <small style={{ fontSize: '12px' }}>
                            Can't read the captcha?
                            <Button
                              variant="link"
                              onClick={() => loadCaptcha()}
                              style={{ margin: "-9px", fontSize: 12, verticalAlign: 'baseline' }}
                            >
                              Click here
                            </Button>
                            to refresh
                          </small>
                        </p>
                        <p className="mb-0"
                          style={{
                            fontSize: "12px",
                            fontWeight: "400",
                            color: "#797979",
                          }}
                        >
                          Please do not login at GSTN portal during this
                          process.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="bookVerify">
              {error ? <Alert variant="danger">{error}</Alert> : null}
              {syncStatusRemindLater ? (
                <Button
                  className="light-cancel-btn"
                  onClick={handleRemindLater}
                >
                  Remind Later
                </Button>
              ) : (
                <Button className="light-cancel-btn" onClick={handleClose}>
                  Close
                </Button>
              )}

              <Button
                className="bookVerifyBtn"
                id="zps_proceed_portal"
                onClick={verifyCatpcha}
                disabled={loading}
              >
                {loading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Loading...
                  </>
                ) : (
                  "Proceed"
                )}
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>

      {otpRequired && <OtpVerifiyForCaptcha handleClose={handleCloseOtp} callFunc={verifyCatpcha} error={error} setError={setError} />}

    </>
  );
};

export default SyncData;
