/* eslint-disable no-useless-catch */
/* eslint-disable import/prefer-default-export */
import axios from "axios";
import { BASE_URL, BASE_URL_GO } from "../app/constants";
import {
  handleSuccess,
  handleErrorWithCode,
  handleError,
} from "./responseHandler";
import { getConfig } from "./common";
import { HEADER_FILE_NAME } from "../app/constants";

export async function getCompanyList(searchStr = "") {
  const url = `${BASE_URL}/api/v1/company/list${searchStr}`;

  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw result.data.message;
    }
    result.data.header.company_name = result.data.header.company_name
      ? result.data.header.company_name
      : "";
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


export async function getCompanyListByPost(data) {
  const url = `${BASE_URL_GO}/api/v1/company/list-all`;

  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw (result.data.message);
    }

    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}


export async function getClientlist(data) {
  const url = `${BASE_URL_GO}/api/v1/company/list-recent`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw result.data.message;
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getCompanyDetailsByID(companyId = "") {
  const url = `${BASE_URL}/api/v1/company/details?company_id=${companyId}`;
  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw result.data.message;
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function updateCompanyDetails(data) {
  const url = `${BASE_URL}/api/v1/company`;

  try {
    const companyData = await axios.put(url, data, getConfig());
    if (Number(companyData.data.status) !== 1) {
      throw companyData.data.message;
    }

    return companyData.data;
  } catch (err) {
    throw handleError(err);
  }
}

export async function getCaptcha() {
  const url = `${BASE_URL}/api/v1/gstportal/captcha`;
  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw result.data.message;
    }
    return result.data;
  } catch (err) {
    throw handleError(err);
  }
}

export async function addCompany(data) {
  const url = `${BASE_URL}/api/v1/company`;

  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw result.data;
    }
    return result.data;
  } catch (err) {
    throw err.response;
  }
}

export async function addCompanyAndHandleErrorWithCode(data) {
  const url = `${BASE_URL}/api/v1/company`;

  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw result.data;
    }
    return result;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function verifyLoginToGSTPortal(data) {
  const url = `${BASE_URL}/api/v1/gstportal/login`;

  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw result.data;
    }
    return result.data;
  } catch (err) {
    throw handleError(err);
  }
}

export async function getCompanyContactDetails(companyId) {
  const url = `${BASE_URL}/api/v1/company/contact-emails?company_id=${companyId}`;

  try {
    const companyData = await axios.get(url, getConfig());
    if (Number(companyData.data.status) !== 1) {
      throw companyData.data.message;
    }

    return companyData.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getCompanyAuthorizationSignatoryDetails(companyId) {
  const url = `${BASE_URL}/api/v1/company/authorized-signatories?company_id=${companyId}`;

  try {
    const companyData = await axios.get(url, getConfig());
    if (Number(companyData.data.status) !== 1) {
      throw companyData.data.message;
    }

    return companyData.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function updateScheduleEmailCompanyDetails(data) {
  const url = `${BASE_URL}/api/v1/company/contact-person`;

  try {
    const companyData = await axios.put(url, data, getConfig());
    if (Number(companyData.data.status) !== 1) {
      throw companyData.data.message;
    }

    return companyData.data;
  } catch (err) {
    throw handleError(err);
  }
}

export async function autoTriggerMailSend(data) {
  const url = `${BASE_URL}/api/v1/settings/email-for-reco2a2b-tally`;

  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw result.data.message;
    }

    return result.data;
  } catch (err) {
    throw handleError(err);
  }
}


export async function downloadExcelTemplateForBulkImport() {
  try {
    const sale_book_url = `${BASE_URL}/api/v1/tentative-company/excel-template`;
    const config = { ...getConfig(), responseType: "blob" };
    const result = await axios.get(sale_book_url, config);
    const file_name =
      result.headers[HEADER_FILE_NAME] ?? "Excel_Template_Import.xlsx";
    const url = window.URL.createObjectURL(new Blob([result.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", file_name);
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function downloadExlTempFor3CACD3CBCDBulkImport(pan) {
  try {
    const sale_book_url = `${BASE_URL_GO}/api/v1/3ca-cb-cd/export-client-master-excel?pan=${pan}`;
    const config = { ...getConfig(), responseType: "blob" };
    const result = await axios.get(sale_book_url, config);
    const file_name =
      result.headers[HEADER_FILE_NAME] ?? "Excel_Template_Import.xlsx";
    const url = window.URL.createObjectURL(new Blob([result.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", file_name);
    document.body.appendChild(link);
    link.click();
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function importFileForBulkImport(data) {
  const url = `${BASE_URL}/api/v1/tentative-company/import`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw result.data.message;
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function bulkImportList(data) {
  const url = `${BASE_URL}/api/v1/tentative-company/list`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw result.data.message;
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function deleteTentativeCompany(id) {
  const url = `${BASE_URL}/api/v1/tentative-company?tc_id=${id}`;
  try {
    const result = await axios.delete(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw result.data.message;
    }

    return result.data;
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function updateTentativeCompany(data) {
  const url = `${BASE_URL}/api/v1/tentative-company`;

  try {
    const companyData = await axios.put(url, data, getConfig());
    if (Number(companyData.data.status) !== 1) {
      throw companyData.data.message;
    }

    return companyData.data;
  } catch (err) {
    throw handleError(err);
  }
}

export async function getGSTINsFromPan(data) {
  const url = `${BASE_URL_GO}/api/v1/gstportal/search/pan`;
  try {
    const result = await axios.post(url, data, getConfig());
    if (Number(result.data.status) !== 1) {
      throw result.data.message;
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}

export async function getClientListSummary(data) {
  const url = `${BASE_URL_GO}/api/v1/company/summary`;
  try {
    const result = await axios.get(url, getConfig());
    if (Number(result.data.status) !== 1) {
      throw result.data.message;
    }
    return handleSuccess(result.data);
  } catch (err) {
    throw handleErrorWithCode(err);
  }
}