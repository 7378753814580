import React from "react";
import Modal from "react-bootstrap/Modal";
import ConnectionConnectorImg from '../../images/connector_img.gif';
import ConnectionSuccessImg from '../../images/connector-success-icon.gif';
import ConnectionCloseImg from '../../images/connector-close-icon.gif';
import ConnectionDownloadImg from '../../images/connector-download-icon.gif';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CONNECTOR_DOWNLOAD_LINK, CONNECTOR_FAQ_LINK } from '../../app/constants';


const ConnectorPopup = (props) => {

    const { show, onClose, connectorStatus } = props;     

    return (
        <>
            <Modal
                className="modal cashcreditledgerpoup"
                show={show}
                onHide={onClose}
            >

                <Modal.Header closeButton className="modal-header cashhead">
                    <div className="modal-title cashledgertext">
                        Running Express Connector For Tally
                    </div>
                </Modal.Header>

                <Modal.Body className="">
                    <div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 pt-3 text-center">
                                    <div className="connector-imgbox">
                                        <img src={ConnectionConnectorImg} alt='' width="50%" />
                                    </div>
                                </div>
                            </div>

                        </div>

                        {/* Scenario 0: Connection Establishment in Progress. */}
                        {connectorStatus === 0 &&
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <p>
                                        <span>Trying to establish connection with Express Connector.</span>
                                    </p>
                                </div>
                            </div>
                        }

                        {/* Scenario 2 OR 3: Connector Not Running (no port found) OR Connector Requires Installation (stale version) */}
                        {(connectorStatus === 2 || connectorStatus === 3) &&
                            <div className="row pt-2">
                                <div className="col-md-12 text-center">
                                    <p>
                                        <span>
                                            <img src={ConnectionCloseImg} alt='' style={{ width: '32px' }} />
                                        </span>
                                        <span className="connector-trying-textfsbgclr">{connectorStatus === 2 ? "Connection with Express Connector could not be established." : "Connection with Express Connector established, but the installed version is out of date."}</span>
                                    </p>
                                </div>

                                <div className="col-md-12 text-center">
                                    <h4 className="infoicon-left pt-3">
                                        <span className="connector-infoicon-clr">
                                            <FontAwesomeIcon icon={faInfoCircle} />
                                        </span>
                                        <span className="connector-mandatory-textfs">It is required to {connectorStatus === 2 ? "install" : "update"} the Express Connector for Login at GST Portal.</span>
                                    </h4>
                                </div>

                                <div className="col-md-5 text-right">
                                    <a href={CONNECTOR_DOWNLOAD_LINK}>
                                        <button className="btn btn-default download-btnbgclr">
                                            <span className="download-right-iconfs">
                                                <img src={ConnectionDownloadImg} alt='' width="25%" />
                                            </span>
                                            <br />Click here to Download
                                        </button>
                                    </a>
                                </div>
                                <div className="col-md-6 pt-2">
                                    <div className="leftside-border">
                                        <p className="down-alraedytextfs">Please download the Installer and install the Express Connector. If it is already installed, please ensure that the Connector is running.</p>
                                        {CONNECTOR_FAQ_LINK
                                            ? (<p className="down-alraedytextfs">Need more help ? <a href={CONNECTOR_FAQ_LINK} className="clickhererefer-fs">Click here to refer installation guide </a></p>)
                                            : (<p className="down-alraedytextfs">Need more help ? Kindly contact our Support team, by using the Call Me option.</p>)
                                        }
                                    </div>
                                </div>
                            </div>
                        }

                        {/* Scenario 1: Connection success and Connector is up to date. All good */}
                        {(connectorStatus === 1 || connectorStatus === 4) &&

                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <p className="mb-1">
                                        <span>
                                            <img src={ConnectionSuccessImg} alt='' wstyle={{ width: '32px' }} />
                                        </span>
                                        <span className="connector-successtextfs-fs">
                                            Connection with Express Connector is established successfully!
                                            <br />
                                            {/* Please visit another browser window, and enter Captcha to Login at GST Portal. You may close this dialog. */}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        }

                        {connectorStatus === 4 &&
                            <>
                                <hr />
                                <div className="row pt-2">
                                    <div className="col-md-12 text-center">
                                        <p>
                                            <span className="connector-infoicon-clr">
                                                <FontAwesomeIcon icon={faInfoCircle} />
                                            </span>
                                            <span className="connector-trying-textfsbgclr">The installed version of Express Connector is out of date. You may update the same.</span>
                                        </p>
                                    </div>

                                    <div className="col-md-5 text-right">
                                        <a href={CONNECTOR_DOWNLOAD_LINK}>
                                            <button className="btn btn-default download-btnbgclr">
                                                <span className="download-right-iconfs">
                                                    <img src={ConnectionDownloadImg} alt='' width="25%" />
                                                </span>
                                                <br />Click here to Download
                                            </button>
                                        </a>
                                    </div>
                                    <div className="col-md-6 pt-2">
                                        <div className="leftside-border">
                                            <p className="down-alraedytextfs">Please download the Installer and install the Express Connector. If it is already installed, please ensure that the Connector is running.</p>
                                            {CONNECTOR_FAQ_LINK
                                                ? (<p className="down-alraedytextfs">Need more help ? <a href={CONNECTOR_FAQ_LINK} className="clickhererefer-fs">Click here to refer installation guide </a></p>)
                                                : (<p className="down-alraedytextfs">Need more help ? Kindly contact our Support team, by using the Call Me option.</p>)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                    </div>

                </Modal.Body>

            </Modal>
        </>
    )

};

export default ConnectorPopup;