/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Window } from "@progress/kendo-react-dialogs";
import $ from 'jquery';
import './WindowWrapperFixWidth.scss';


const WindowWrapperFixWidth = (props) => {
    const {
        title,
        onClose,
        initialWidth = 1250,
        initialHeight = 350,
        initialLeft = 10,
        initialTop = 0,
        isModalView = false,
        resizable = false,
        style,
        showInCenter = null,
        className,
        showDiffClass,
        windowActions = true,
        windowPosition = false,
    } = props;
    const [top, setTop] = useState(initialTop);
    const [left, setLeft] = useState(initialLeft);
    useEffect(() => {
        if (showInCenter) {
            const windowWidth = window.innerWidth
            const windowHeight = window.innerHeight
            setLeft((windowWidth / 2) - (initialWidth / 2))
            if(windowPosition){
                setTop(parseInt($(document).scrollTop()) + 50);
            }else{
                setTop((windowHeight / 2) - (initialHeight / 2));
            }
            
        }

    }, [showInCenter]);
    return (
        <Window
            maximizeButton={() => null}
            minimizeButton={() => null}
            title={title}
            onClose={onClose}
            initialHeight={initialHeight}
            initialWidth={initialWidth}
            left={left}
            top={top}
            width={initialWidth}
            className={showDiffClass ? className : `gstr9reportspopup ${windowActions ? "" : "custom-window"}`}
            modal={isModalView}
            resizable={resizable}
            onMove={(e) => {
                setLeft(Math.min( window.innerWidth - e.width - 15, e.left))
                setTop(Math.min( window.innerHeight - e.height - 15, e.top))
              }}
            style={{ ...style }}
            doubleClickStageChange={false}
        >
            {props.children}
        </Window>
    );
};

export default WindowWrapperFixWidth;