/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import ConnectorPopup from "./ConnectorPopup";
import { fetchCompanyListToConnector, storeCompanyListFromConnector, syncCompanyToConnector } from "../../api/serverConnectorAPI";
import { performTallyAction } from "../../api/localConnectorAPI";
import { tallyMapCompany, tallyMappingSyncStatus, tallyUnMapCompany, toggleCompanySyncType } from "../../api/booksAPI";
import { HEADER_CONNECTOR_DIGEST_KEY, CONNECTOR_ERRORS_MAPPING } from "../../app/constants";
import { getRunningConnectorUsability, tallyListAfterAddInLocal, tallySyncUpdateLocalStorage } from "../../utils/UtilityFunctions";
import ConfirmPopup from "../../components/ConfirmPopup";
import { selectHeaderData } from "../header/headerSlice";
import moment from "moment";
import store from "../../app/store";
import { setTallySyncStatus } from "../header/tallySyncStatusSlice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import './ReturnDashboard.css'
const ImportBookTallyMyPC = (props) => {
    const { companyId, setError, setSuccess, myPcClicked, setMyPCPopup, setPcOption, initialMappedInfo, setInitialMappedInfo } = props;
    const [connectorPopup, setConnectorPopup] = useState(false);
    const [connectorPort, setConnectorPort] = useState(0);
    const [connectorStatus, setConnectorStatus] = useState(0);
    const [tallyCompanyList, setTallyCompanyList] = useState([]);
    const [tallyFound, setTallyFound] = useState(false)
    const [fetchComplete, setFetchComplete] = useState(false)
    const [confirmPopup, setConfirmPopup] = useState(false);
    const [confirmText, setConfirmText] = useState("");
    const [selectedTallyCompanyId, setSelectedTallyCompanyId] = useState("");
    const [selectedTallyCompanyName, setSelectedTallyCompanyName] = useState("");
    const [selectedTallyCompanyGstNo, setSelectedTallyCompanyGstNo] = useState("");
    const [mappedInfo, setMappedInfo] = useState({});
    const [autoSyncToggle, setAutoSyncToggle] = useState(false);
    const [unmappedClicked, setUnmappedClicked] = useState(false);
    const [tallyPort, setTallyPort] = useState(localStorage.getItem("pc-tally-port") ?? "")
    const [tallyError, settallyError] = useState(null)
    const [showTallyPortOption, setShowTallyPortOption] = useState(false)

    const timer = useRef(null);
    const headerData = useSelector(selectHeaderData);
    const location = useLocation();
    const params = queryString.parse(location.search);

    const [unMappedCompany, setUnMappedCompany] = useState(false);
    const [domReady, setDomReady] = useState(false)

    const fetchMappingSyncStatus = (calledOnClick) => {
        async function fetchStatus() {
            try {
                const result = await tallyMappingSyncStatus(companyId);
                if (result?.data) {
                    setMappedInfo(result?.data ?? {});
                    setAutoSyncToggle(result?.data?.sync_type === "AUTO" ? true : false)
                    if (result?.data?.mapping_status === 0) {
                        if (calledOnClick) {
                            setConnectorPopup(true);
                            runConnectorWhenFound({ fetchList: true });
                            setPcOption(true)
                        }
                        // Clear timeout on unmount
                        return () => clearTimeout(timer.current);
                    } else if (result?.data?.mapping_status === 1) {
                        setConnectorPopup(false);
                        setSelectedTallyCompanyId(result?.data?.tally_company_id ?? "")
                        setPcOption(false)
                    }
                }
            } catch (err) {
                setError(err.message)
            }
        }
        fetchStatus()
    };

    const runConnectorWhenFound = async (data) => {
        // Run a continuous loop every 2 seconds
        // Keep finding running Connector details - until we are in success or updatable scenario
        const runningConnectorResult = await getRunningConnectorUsability();

        let connectorRunning = false;
        if (runningConnectorResult.port === 0) {
            // Connector is not running or not installed yet. Need to install or Start.
            setConnectorStatus(2);
            connectorRunning = false;
        } else if (runningConnectorResult.install === 1) {
            // Connector is installed, but stale version. Need fresh install.
            setConnectorStatus(3);
            connectorRunning = false;
            setConnectorPort(runningConnectorResult.port)
        } else if (runningConnectorResult.update === 1) {
            // Connector is installed, has old version and can be updated. However, work can still go on.
            setConnectorStatus(4);
            connectorRunning = true;
            setConnectorPort(runningConnectorResult.port);
        } else {
            // Connection success and Connector is up to date. All good
            setConnectorStatus(1);
            connectorRunning = true;
            setConnectorPort(runningConnectorResult.port)
        }
        if (connectorRunning === true && runningConnectorResult.install === 0) {
            if (data?.fetchList) tallyFetchCompanyList(runningConnectorResult.port);
            if (data?.runSync) syncNowForMyPc(runningConnectorResult.port)
            setTimeout(() => {
                setConnectorPopup(false)
            }, 0)
            return;
        }

        // Connector Still not Running. Run again after 2 seconds
        timer.current = setTimeout(() => runConnectorWhenFound(data), 2000);
    }


    const tallyFetchCompanyList = (port) => {
        async function fetchData() {
            const request = {
                company_id: companyId,
                port: tallyPort?.length > 0 ? Number(tallyPort) : null
            }
            try {
                const response = await fetchCompanyListToConnector(request);
                const responseData = response?.data ?? null;
                const responseHeader = response?.headers ?? null;
                if (Number(responseData.status) === 1) {
                    const connectorRequest = {
                        "data": responseData.data
                    };
                    let extraHeaderToConnector = {};
                    extraHeaderToConnector[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;


                    try {
                        const connectorResponse = await performTallyAction((connectorPort === 0 ? port : connectorPort), connectorRequest, extraHeaderToConnector);
                        const payload = {
                            data: connectorResponse?.data?.data ?? null
                        }
                        const connectorResponseHeaders = connectorResponse?.headers ?? null;
                        let extraHeaderToServer = {};
                        extraHeaderToServer[HEADER_CONNECTOR_DIGEST_KEY] = connectorResponseHeaders[HEADER_CONNECTOR_DIGEST_KEY] ?? null;

                        if (connectorResponse?.data) {
                            const finalResult = await storeCompanyListFromConnector(payload, extraHeaderToServer);
                            const currentUserGSTIN = finalResult.data?.header?.gst_number ?? "";
                            if (finalResult?.data?.data) {
                                const tallyList = tallyListAfterAddInLocal(finalResult.data.data, currentUserGSTIN)
                                const sortArray = tallyList.sort((a, b) => (a.tally_gstin === headerData?.gst_number && b.tally_gstin !== headerData?.gst_number) ? 1 : -1).sort((a, b) => (!a.is_running && b.is_running) ? 1 : -1)
                                setTallyCompanyList(sortArray);
                            }
                            setTallyFound(true)
                            setError(false)
                            if (tallyPort?.length > 0) {
                                localStorage.setItem("pc-tally-port", tallyPort)
                            } else {
                                localStorage.removeItem("pc-tally-port")
                            }

                        }
                        setError(null)

                    } catch (error) {
                        if (error.message === "tally.not_running_on_port") {
                            settallyError(CONNECTOR_ERRORS_MAPPING?.[error.message])
                        } else if (error.message !== "tally.not_running_on_port" && error.message !== "tally.not_running") {
                            setError(CONNECTOR_ERRORS_MAPPING?.[error.message] || "Something Went Wrong");
                            setMyPCPopup(false)
                        }
                        setTallyFound(false)
                        setSuccess(null)

                        // setConnectorPort(0);
                    }
                }
            } catch (err) {
                setError(err.message);
                setConnectorPopup(false);
                setSuccess(null)

            } finally {
                setFetchComplete(true)
            }
        }
        fetchData()
    };


    const mapTallyCompany = () => {
        setConfirmPopup(true);
        if (selectedTallyCompanyGstNo === headerData?.gst_number && selectedTallyCompanyGstNo !== "") {
            setConfirmText("If there is any existing data imported from excel or entered manually in the books section, it will be appended with Tally Purchase data.");
        } else if (selectedTallyCompanyGstNo !== headerData?.gst_number) {
            setConfirmText(`You cannot map Express GST company ${headerData?.company_name} with tally company ${selectedTallyCompanyName} as both of them have different GSTIN.`);
        } else if (selectedTallyCompanyGstNo === "") {
            setConfirmText(`You cannot map Express GST company ${headerData?.company_name} with tally company ${selectedTallyCompanyName} as GSTIN is Not Available`);
        } else {
            setConfirmText(`Something Went Wrong`);
        }
    };


    const mappingFromTallyToExpressGst = () => {
        async function mapData() {
            const payload = {
                tally_company_id: selectedTallyCompanyId,
                company_id: params?.company_id
            }
            try {
                const result = await tallyMapCompany(payload)
                if (result?.data) {
                    setSuccess(`Express GST Company ${headerData?.company_name} has successfully been mapped with Tally company ${selectedTallyCompanyName}. We have automatically set syncing at regular interval. If you wish to change this setting you can set it to Manual Trigger.`)
                    fetchMappingSyncStatus(true)
                }
            } catch (err) {
                setError(err.message);
            }
        }
        mapData()
    };

    const unMappingTallyFromExpressGst = () => {
        //setError("If you are disconnecting this Tally Profile, the further sync will stop happening, however the data already synced would not be impacted. If you want to delete the relevant data also, you can do so by using delete options for Sales / Purchase individually, provided in individual Client / GSTIN.")
        async function unMapData() {
            const payload = {
                tally_company_id: selectedTallyCompanyId,
                company_id: params?.company_id
            }
            try {
                const result = await tallyUnMapCompany(payload)
                if (result?.data) {
                    setSuccess("Unmapped succesfully!!");
                    setSelectedTallyCompanyId("")
                    setUnMappedCompany(false);
                    // setConnectorPopup(true);
                    // runConnectorWhenFound();
                    setUnmappedClicked(true)
                    fetchMappingSyncStatus(true)

                    if (setInitialMappedInfo) {
                        setInitialMappedInfo({ ...initialMappedInfo, mapping_status: 0 })
                    }

                    // Clear timeout on unmount
                    return () => clearTimeout(timer.current);
                }
            } catch (err) {
                setError(err.message);
            }
        }
        unMapData()
    };


    const syncNowForMyPc = async (connectorPort) => {
        if (connectorPort) {
            async function syncData() {
                const request = {
                    company_id: params?.company_id
                }
                try {
                    const response = await syncCompanyToConnector(request);
                    const responseData = response?.data ?? null;
                    const responseHeader = response?.headers ?? null;

                    if (Number(responseData.status) === 1) {
                        const connectorRequest = {
                            "data": responseData.data
                        };
                        let extraHeaderToConnector = {};
                        extraHeaderToConnector[HEADER_CONNECTOR_DIGEST_KEY] = responseHeader[HEADER_CONNECTOR_DIGEST_KEY] ?? null;

                        try {
                            const result = await performTallyAction(connectorPort, connectorRequest, extraHeaderToConnector);
                            if (result?.data?.data) {
                                const tenantMobile = headerData?.mobile_number
                                store.dispatch(setTallySyncStatus({ syncStarted: false }))
                                tallySyncUpdateLocalStorage(tenantMobile, headerData?.gst_number, JSON.parse(atob(result.data.data)))
                                store.dispatch(setTallySyncStatus({ syncStarted: true }))

                            }
                            setError(null)

                        } catch (error) {
                            if (error.message === "tally.not_running_on_port" || error.message === "tally.not_running") {
                                setError("We are not able to sync the data because either Tally is not running or Port is not between 1025 and 65535. Please run the Tally or Unmap the company, correct the Port no. and Map again.");
                            } else {
                                setError(CONNECTOR_ERRORS_MAPPING?.[error.message] || "Something Went Wrong");
                            }
                            setSuccess(null)
                            // setConnectorPort(0);
                        }
                    }
                } catch (err) {
                    setError(err.message)
                    setSuccess(null)

                }
            }
            syncData()
        }
    };

    const saveResponseForAutoSyncToggle = (toggle) => {
        const request = {
            company_id: params?.company_id,
            tally_company_id: selectedTallyCompanyId,
            sync_type: toggle
        }
        async function saveToggle() {
            try {
                await toggleCompanySyncType(request);
            } catch (err) {

            }
        }
        saveToggle()
    }


    useEffect(() => {
        setMappedInfo(initialMappedInfo);
        setAutoSyncToggle(initialMappedInfo?.sync_type === "AUTO" ? true : false)
        if (initialMappedInfo?.mapping_status === 1) {
            setConnectorPopup(false);
            setSelectedTallyCompanyId(initialMappedInfo?.tally_company_id ?? "")
        } else if (initialMappedInfo?.mapping_status === 0 && myPcClicked) {
            setConnectorPopup(true);
            runConnectorWhenFound({ fetchList: true });
        }
    }, [companyId, myPcClicked, initialMappedInfo]);
    useEffect(() => {
        setDomReady(true)
    }, [])

    return (
        <>


            {connectorPopup &&
                <ConnectorPopup
                    show={connectorPopup}
                    onClose={() => setConnectorPopup(false)}
                    connectorStatus={connectorStatus}
                />
            }

            {confirmPopup &&
                <ConfirmPopup
                    show={confirmPopup}
                    handleClose={() => setConfirmPopup(false)}
                    handleProceed={() => {
                        if (unMappedCompany) {
                            unMappingTallyFromExpressGst();
                        } else if (selectedTallyCompanyGstNo === headerData?.gst_number && selectedTallyCompanyGstNo !== "") {
                            mappingFromTallyToExpressGst();
                        }
                        setConfirmPopup(false);
                    }}
                    confirmText={confirmText}
                    confirmButtonText={"Proceed"}
                    showConfrimBtn={(unMappedCompany || (selectedTallyCompanyGstNo === headerData?.gst_number && selectedTallyCompanyGstNo !== "") ? true : false)}
                    cancelButtonText={(unMappedCompany || (selectedTallyCompanyGstNo === headerData?.gst_number && selectedTallyCompanyGstNo !== "") ? "Cancel" : "Ok")}
                    textClass="tally_text"

                />
            }



            {mappedInfo && mappedInfo?.mapping_status === 1 ?
                <div class="row pt-2">
                    {domReady
                        ? ReactDOM.createPortal(
                            <div className="col-md-12 pb-0 px-0">
                                <button className={`btn btn-default askclientupdatedata_bgclr ml-3  ${autoSyncToggle ? 'disabled' : ''}`} onClick={() => {
                                    if (!autoSyncToggle) {
                                        setConnectorPopup(true); runConnectorWhenFound({ runSync: true });
                                    }
                                }}>
                                    Sync Now
                                </button>
                                <span className={`tallyauto_sync_textfs  tallyauto_sync_border ml-1`} >
                                    <span className="tally_autosync_textfs mr-2" style={{ verticalAlign: 'text-bottom' }} >Auto Sync</span>
                                    <label className="switch autosync_togglebtn">
                                        <input type="checkbox"
                                            onChange={(e) => {
                                                setAutoSyncToggle(!autoSyncToggle);
                                                if (e.target.checked) {
                                                    saveResponseForAutoSyncToggle("AUTO");
                                                } else if (!e.target.checked) {
                                                    saveResponseForAutoSyncToggle("MANUAL");
                                                }
                                            }}
                                            checked={autoSyncToggle ? true : false}
                                            value={mappedInfo?.sync_type ?? ''}
                                        />
                                        <span className="slider round ">	</span>
                                    </label>
                                </span>

                            </div>
                            , document.querySelector('#tally-sync-box')
                        )
                        : null
                    }





                    <div class="col-lg-12">
                        <div class="card tallyconfg_card">
                            <p class="tally_autosync_lastsyn_textfs mb-1">
                                Last Synced On : <b><span class="pr-1">{mappedInfo?.last_sync_at ? moment((mappedInfo?.last_sync_at).slice(0, 10)).format('DD/MM/YYYY') : ""}</span></b>
                            </p>

                            <p class="tally_config_textfs mb-1">
                                The Tally data is set to sync from  <b>{mappedInfo?.tally_name}</b> to  MY PC & Port <b>{mappedInfo?.tally_port}</b> at  {autoSyncToggle ? "Automatic Intervals" : "Manually"}.

                            </p>

                            <p class="text-center mb-2">
                                <button class="btn btn-default askclientforupdate_btn" onClick={() => {
                                    setUnMappedCompany(true);
                                    //unMappingTallyFromExpressGst()
                                    setConfirmPopup(true);
                                    setConfirmText('If you are disconnecting this Tally Profile, the further sync will stop happening, however the data already synced would not be impacted. If you want to delete the relevant data also, you can do so by using delete options for Sales / Purchase individually, provided in individual Client / GSTIN.');
                                }}>
                                    Unmap
                                </button>



                            </p>

                            <p class="tally_config_textfs">
                                If wish to change the tally company click on Unmap.
                            </p>

                        </div>

                    </div>

                    <div class="col-md-12 pt-1">
                        <div class="card tallyconfg_card">
                            <p class="tally_config_Ginfofs mb-0">
                                General Instructions:
                            </p>
                            <p class="tally_config_textfs mb-1">
                                1. Choose appropriate option from Configure with Tally and
                                ExpressGST will scan your Entire network and Tally instances running thereof.

                            </p>
                            <p class="tally_config_textfs ">
                                2. Please ensure the below points in the entire network-
                            </p>
                            <ul class="importbooksdt_tally_listbox py-0 mb-0">
                                <li>
                                    <span class="pr-0 tally_listbox_checkicon_clrblue">
                                        <i class="fa fa-check" aria-hidden="true"></i>
                                    </span>
                                    Updated Connector is installed on the PC
                                </li>
                                <li>
                                    <span class="pr-0 tally_listbox_checkicon_clrblue">
                                        <i class="fa fa-check" aria-hidden="true"></i>
                                    </span>
                                    Tally is up and running on the PC
                                </li>
                                <li>
                                    <span class="pr-0 tally_listbox_checkicon_clrblue">
                                        <i class="fa fa-check" aria-hidden="true"></i>
                                    </span>
                                    Tally Company is open from which you want to sync.
                                </li>

                            </ul>
                        </div>
                    </div>

                </div>
                :
                <>
                    {(connectorPort && fetchComplete && (myPcClicked || unmappedClicked)) ?
                        <>
                            {tallyFound ?
                                <div className="row">
                                    <div className="col-md-12 mt-2">
                                        <div className="card">

                                            <p className="seltally_boxbgclr mb-0">
                                                Select Tally Company from the list that you want to Map with
                                                <b> {headerData?.company_name}</b>
                                            </p>

                                            <div className="table-responsive table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs" style={{ maxHeight: '250px' }}>
                                                <table className="table table-striped  tally_selmypc_tb mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th width="15"></th>
                                                            <th width="300">Company Name</th>
                                                            <th width="120">GSTIN</th>
                                                            <th width="150">Books FY</th>
                                                            <th width="100">Port</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {(tallyCompanyList && tallyCompanyList?.length > 0) ? tallyCompanyList.map((item) => {
                                                            return (
                                                                <tr className={`${item?.is_running ? '' : 'textclr_lightgrey'}`} style={{ backgroundColor: `${item?.tally_gstin === headerData?.gst_number ? '#fff9c5' : '#ffe3e6'}` }}>
                                                                    <td>
                                                                        <input type="radio" className="tally_mypc_rediovert"
                                                                            onChange={() => {
                                                                                if (item?.is_running) {
                                                                                    setSelectedTallyCompanyId(item?.tally_company_id);
                                                                                    setSelectedTallyCompanyName(item?.mapped_company_name)
                                                                                    setSelectedTallyCompanyGstNo(item?.tally_gstin)
                                                                                }
                                                                            }}
                                                                            disabled={(item?.mapped_company_id !== null || !item?.is_running) ? true : false}
                                                                            checked={item?.tally_company_id === selectedTallyCompanyId ? true : false}
                                                                        />
                                                                    </td>
                                                                    <td className={`onhoverdipaly_egcomp text-left`}
                                                                        data-toggle="tooltip" data-placement="top"
                                                                        title={`${(item?.mapped_company_name || item?.mapped_company_name !== "") ? `This company is already mapped with the Express GST company ${headerData?.company_name}. If you wish to map tally company ${item?.mapped_company_name} with Express GST company ${headerData?.company_name}, please un-map the tally company from Express GST company ${headerData?.company_name} first and come back.` : ""}`}
                                                                    >
                                                                        {(item?.tally_name || item?.tally_name !== "") ? item?.tally_name : "-"}
                                                                        {(item?.mapped_company_id !== null || item?.mapped_company_name !== "") &&
                                                                            <span className="egcomp_textfs">{item?.mapped_company_name}</span>
                                                                        }
                                                                    </td>
                                                                    <td className="text-left">{item?.tally_gstin ?? "No GSTIN"}</td>
                                                                    <td className="text-center textclr_red">{`${item?.tally_start_fy}-${(String(item?.tally_start_fy + 1)).slice(2)}`}</td>
                                                                    <td className="text-center">{item?.tally_port ?? "-"}</td>
                                                                </tr>
                                                            )
                                                        })
                                                            :
                                                            <tr>
                                                                <td colSpan={5} className="text-center">No Records Found!!</td>
                                                            </tr>
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="row pt-2 pb-2">
                                                <div className="col-md-12 text-center">
                                                    <button className="btn btn-default tally_mapbtnbgclr" onClick={() => {
                                                        if (selectedTallyCompanyId === "") {
                                                            setError("Please select a company to map!!")
                                                        } else {
                                                            mapTallyCompany()
                                                        }
                                                    }}>Map</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :

                                <div class="row">
                                    <div className="col-md-12 mt-2">
                                        <div className="card">
                                            <div class="col-md-12">
                                                <p class="tallyport_terxtfs mt-1" style={{ fontSize: "12px" }}>
                                                    Your Tally is not open. Either open the Tally and
                                                    <span style={{ color: "#31719b", cursor: "pointer" }} onClick={() => { setTallyPort(""); runConnectorWhenFound({ fetchList: true }); }}> Retry</span>
                                                </p>
                                                <p class="tallyport_terxtfs mt-1" style={{ fontSize: "12px" }}>
                                                    OR <br></br> Check the Port No. entered in your Tally to initiate configuration. It must be between 1025 and 65535. <span style={{ color: "#31719b", cursor: "pointer" }} onClick={() => { setShowTallyPortOption(true) }}> Change Now</span>

                                                </p>
                                                {showTallyPortOption &&
                                                    <div class="form-row tallyport_form">

                                                        <div class="form-group col-sm-4">
                                                            <label for="inputEmail4">Port Number<span class="red_text">*</span>
                                                                <div class="port-tooltip"><FontAwesomeIcon icon={faExclamationCircle} />
                                                                    <span class="tooltiptext">{`How to check port in tally: \n Press F1> Help > Settings > Connectivity > Client Server Config > Port`}</span>
                                                                </div>


                                                            </label>
                                                        </div>
                                                        <div class="form-group col-sm-8">

                                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                <input type="text" maxLength={5} value={tallyPort} placeholder="9000" class="form-control tallyinput_fs tally-port-input" onChange={(e) => setTallyPort(e.target.value)} />
                                                                <button class=" mr-2 tally-port-btn" onClick={() => {
                                                                    if (Number(tallyPort) > 65535 || Number(tallyPort) < 1025) {
                                                                        settallyError("Tally Port Should be between 1025 and 65535")
                                                                    } else {
                                                                        settallyError(null)
                                                                        runConnectorWhenFound({ fetchList: true });
                                                                    }
                                                                }}>
                                                                    Proceed
                                                                </button></div>

                                                            {tallyError && <span style={{ color: "red" }}>{tallyError}</span>}
                                                        </div>
                                                    </div>}
                                            </div>
                                        </div> </div> </div>}


                        </> : null}

                    <div className="row pt-2 mb-2">
                        <div className="col-md-12 pt-1">
                            <div className="card tallyconfg_card">
                                <p className="tally_config_Ginfofs mb-0">
                                    General Instructions:
                                </p>
                                <p className="tally_config_textfs mb-1">
                                    1.  Choose appropriate option from Configure with Tally and ExpressGST will scan your Entire network and Tally instances running thereof.

                                </p>
                                <p className="tally_config_textfs ">
                                    2. Please ensure the below points in the entire network-
                                </p>
                                <ul className="importbooksdt_tally_listbox py-0 mb-0">
                                    <li>
                                        <span className="pr-0 tally_listbox_checkicon_clrblue">
                                            <i className="fa fa-check" aria-hidden="true"></i>
                                        </span>
                                        Updated Connector is installed on the PC
                                    </li>
                                    <li>
                                        <span className="pr-0 tally_listbox_checkicon_clrblue">
                                            <i className="fa fa-check" aria-hidden="true"></i>
                                        </span>
                                        Tally is up and running on the PC
                                    </li>
                                    <li>
                                        <span className="pr-0 tally_listbox_checkicon_clrblue">
                                            <i className="fa fa-check" aria-hidden="true"></i>
                                        </span>
                                        Tally Company is open from which you want to sync.
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </>}
        </>

    )

};

export default ImportBookTallyMyPC;