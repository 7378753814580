import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import { BASE_PATH } from '../../app/constants';
import LinkingBy2A from './2A/LinkingBy2A';
import LinkingBy2B from './2B/LinkingBy2B';
import ModelSellerSelection from './ModelSellerSlection';
import { decodeBase64, decodeParamsHeaderMulti, transformData, encodeParams } from '../../utils/UtilityFunctions';
import BooksData from '../bookList/BooksDataV2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { selectHeaderData } from "../header/headerSlice";
import './multiLinking.scss';
import '../2AReco/V2/rateReco/RateReco.css';
import TallyProgressPopup from '../returnDashboard/TallyProgressPopup';

const MultiLinking = () => {
    const location = useLocation();
    const headerData = useSelector(selectHeaderData);
    const params = queryString.parse(location.search);
    const { company_id, encodedParams, return_financial_year } = params;
    const { mobile_number, gst_number } = headerData;


    const [state, setState] = useState({
        requestedDataFormat: [],
        selectedMode: null,
        sellerListingResponse: [],
        selectedSeller: "",
        fullResponse: {},
        model: false,
        activeTab: localStorage.getItem('activeTab') || '2A',
        loading: true,
        componentKey: Date.now(),
    });

    const [bookListPopup, setBookListPopup] = useState(false);
    const [success, setSuccess] = useState(null);

    useEffect(() => {
        const { selectedYears, selectedMonths } = decodeParamsHeaderMulti(encodedParams);
        const transformedData = transformData(selectedYears, selectedMonths);
        const sellerKey = `selectedSeller_${mobile_number}_${gst_number}`;
        const savedSelectedSeller = localStorage.getItem(sellerKey);

        let newState = {
            requestedDataFormat: transformedData,
        };

        if (savedSelectedSeller) {
            const decodedSavedSeller = decodeBase64(savedSelectedSeller);
            const parsedSelectedSeller = JSON.parse(decodedSavedSeller);
            const savedSelectedSellerString = `${parsedSelectedSeller.supplier_name} (${parsedSelectedSeller.gst_number})`;

            newState.fullResponse = parsedSelectedSeller;
            newState.selectedSeller = savedSelectedSellerString;
        }

        setState(prevState => ({
            ...prevState,
            ...newState,
        }));
    }, [encodedParams, company_id, gst_number, mobile_number]);

    useEffect(() => {
        const modeKey = `selectedOption_${mobile_number}_${gst_number}`;
        const savedMode = localStorage.getItem(modeKey);
        const shouldShowModal = !savedMode || savedMode === "null";

        setState(prevState => ({
            ...prevState,
            loading: false,
            model: shouldShowModal,
            selectedMode: savedMode === "null" ? null : savedMode,
        }));
    }, [mobile_number, gst_number]);

    const handleSellerSelect = useCallback((sellerInfo, supplier) => {
        const setLocal = !supplier.supplier_name ?
            { ...supplier, supplier_name: "Name not available" } :
            supplier;

        const key = `selectedSeller_${mobile_number}_${gst_number}`;
        const encodedSeller = encodeParams(setLocal);
        localStorage.setItem(key, encodedSeller);

        setState(prevState => ({
            ...prevState,
            selectedSeller: sellerInfo,
            fullResponse: setLocal,
        }));
    }, [mobile_number, gst_number]);


    const handleSelectionConfirm = useCallback((option, seller) => {
        const modeKey = `selectedOption_${mobile_number}_${gst_number}`;
        localStorage.setItem(modeKey, option);

        setState(prevState => ({
            ...prevState,
            selectedMode: option,
            selectedSeller: seller || prevState.selectedSeller,
            model: false,
            componentKey: Date.now(),
        }));
    }, [mobile_number, gst_number]);

    const handleTabClick = useCallback(tab => {
        localStorage.setItem('activeTab', tab);
        setState(prevState => ({ ...prevState, activeTab: tab }));
    }, []);

    return (
        <>
            <TallyProgressPopup />

            {(!state.loading && (!state.selectedMode || state.model)) && (
                <ModelSellerSelection
                    setModel={value => setState(prevState => ({ ...prevState, model: value }))}
                    requestedDataFormat={state.requestedDataFormat}
                    handleSelectionConfirm={handleSelectionConfirm}
                    company_id={company_id}
                    setSelectedSeller={selectedSeller => setState(prevState => ({ ...prevState, selectedSeller }))}
                    setFullResponse={fullResponse => setState(prevState => ({ ...prevState, fullResponse }))}
                    model={state.model}
                    mobile_number={mobile_number}
                    gst_number={gst_number}
                />
            )}

            <div key={state.componentKey} className={`container-fluid mt-2 ${state.model ? 'disabled-overlay' : ''}`}>
                <div className="row mb-1">
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pr-0">
                        <Link className="backtoclrlistofcomp" to={`${BASE_PATH}reco-dashboard?company_id=${company_id}&encodedParams=${encodedParams}&return_financial_year=${return_financial_year}&year=${return_financial_year}`}>&lt; Back to 2A / 2B Dashboard </Link>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-7 col-sm-8 col-xs-12">
                        <div className="supplistofcomp">
                            <h4 className="">Many to Many Linking
                                <span className="pl-3">
                                    <a
                                        className={`${state.activeTab === '2A' ? 'active' : ''}`}
                                        id="nav-home-tab"
                                        data-toggle="tab"
                                        href="#supperwise_reco_by2ab_2a"
                                        role="tab"
                                        aria-controls="nav-home"
                                        aria-selected={state.activeTab === '2A'}
                                        onClick={() => handleTabClick('2A')}
                                    >
                                        <button type="button" className={`${state.activeTab === '2A' ? 'btn btn-primary by2abtn' : 'btn btn-primary by2bbtn'}`}>By 2A</button>
                                    </a>
                                    <a
                                        className={`${state.activeTab === '2B' ? 'active' : ''}`}
                                        id="nav-profile-tab"
                                        data-toggle="tab"
                                        href="#supperwise_reco_by2ab_2b"
                                        role="tab"
                                        aria-controls="nav-profile"
                                        aria-selected={state.activeTab === '2B'}
                                        onClick={() => handleTabClick('2B')}
                                    >
                                        <button type="button" className={`${state.activeTab === '2A' ? 'btn btn-primary by2bbtn' : 'btn btn-primary by2abtn by2bbtnbor'}`}>By 2B</button>
                                    </a>
                                </span>
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col-md-12 mt-0 pt-0">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="tab-content" id="nav-tabContent">
                                    {state.activeTab === '2A' && (
                                        <LinkingBy2A
                                            selectedMode={state.selectedMode}
                                            setSelectedMode={setMode => {
                                                setState(prevState => ({ ...prevState, selectedMode: setMode }));
                                                localStorage.setItem(`selectedOption_${mobile_number}_${gst_number}`, setMode);
                                            }}
                                            selectedSeller={state.selectedSeller}
                                            handleSellerSelect={handleSellerSelect}
                                            company_id={company_id}
                                            requestedDataFormat={state.requestedDataFormat}
                                            fullResponse={state.fullResponse}
                                            mobile_number={mobile_number}
                                            gst_number={gst_number}
                                            activeTab={state.activeTab}
                                            gstrKey={'gstr2a'}
                                        />
                                    )}
                                    {state.activeTab === '2B' && (
                                        <LinkingBy2B
                                            selectedMode={state.selectedMode}
                                            setSelectedMode={setMode => {
                                                setState(prevState => ({ ...prevState, selectedMode: setMode }));
                                                localStorage.setItem(`selectedOption_${mobile_number}_${gst_number}`, setMode);
                                            }}
                                            selectedSeller={state.selectedSeller}
                                            handleSellerSelect={handleSellerSelect}
                                            company_id={company_id}
                                            requestedDataFormat={state.requestedDataFormat}
                                            fullResponse={state.fullResponse}
                                            mobile_number={mobile_number}
                                            gst_number={gst_number}
                                            activeTab={state.activeTab}
                                            gstrKey={'gstr2b'}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="leftsidesummarypopup active_announcement" style={{ zIndex: '1051' }}>
                <div className="AnnouncementsIcon" data-toggle="modal" data-target="#updatebooks2adatam" onClick={() => setBookListPopup(true)}>
                    <div className="Announcements">
                        <div className="Announcements-wrapper has-unseen-items Dropdown">
                            <div className="Dropdown-toggle Button">
                                <div className="Announcements-bubble">&nbsp;</div> <FontAwesomeIcon icon={faBullhorn} style={{ color: '#fff', fontSize: '11px', transform: 'rotate(-38deg)' }} />
                            </div>
                        </div>
                    </div>
                </div>
                <span data-toggle="modal" data-target="#updatebooks2adatam" id="bookPopup" className="mb-0 btn" type="button" onClick={() => setBookListPopup(true)}>
                    Import Books Data
                </span>
            </div>

            {bookListPopup && (
                <BooksData
                    company_id={company_id}
                    show={bookListPopup}
                    handleClose={() => setBookListPopup(false)}
                    filterYear={return_financial_year}
                    postData={state.requestedDataFormat}
                    setSuccess={setSuccess}
                    success={success}
                    handleProcced={(msg, isRefresh = false) => {
                        setBookListPopup(false);
                        if (msg) setSuccess(msg);
                        if (isRefresh) { window.location.reload(); }
                    }}
                />
            )}
        </>
    );
};

export default MultiLinking;
